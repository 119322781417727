export const citizenshipOptions: Record<string, string> = {
  UsCitizen: 'US Citizen',
  PermanentResidentAlien: 'Permanent Resident',
  NonPermanentResidentAlien: 'Non Permanent Resident',
  ForeignNational: 'Foreign National',
  Itin: 'Itin',
}

export const EscrowTypeLabels: Record<string, string> = {
  notWaived: 'Not Waived',
  waiveBoth: 'Waive Both',
  waiveInsurance: 'Waive Insurance',
}

export const occupancyTypeOptions = {
  Primary: 'Primary',
  Secondary: 'Second Home',
  Investment: 'Investment',
}

export const commercialOccupancyTypeOptions = [
  'Owner Occupied',
  'Non Owner Occupied',
  'Partially Owner Occupied',
  'Vacant',
  'Partially Vacant',
]

export const commercialOccupancyTypes: Record<string, string> = {
  'Owner Occupied': 'Primary',
  'Non Owner Occupied': 'Investment',
  'Partially Owner Occupied': 'Investment',
  Vacant: 'Investment',
  'Partially Vacant': 'Investment',
}

export const prePaymentPenaltyTermInMonthsOptions: Record<string, string> = {
  '0': '0 years',
  '12': '1 years',
  '24': '2 years',
  '36': '3 years',
  '48': '4 years',
  '60': '5 years',
}

export const propertyTypeOptions: Record<string, string> = {
  'SFR-Detached': 'SingleFamily',
  'SFR-Attached': 'SingleFamily',
  Condo: 'Condominium',
  '2-4 Units': 'TwoFourUnits',
  PUD: 'Pud',
  'Mixed-Use': 'MixedUse',
  Modular: 'Modular',
  'Multifamily 5+': 'FivePlusUnits',
  Industrial: 'Industrial',
  Manufactured: 'Manufactured',
  'Self Storage': 'SelfStorage',
  Office: 'Office',
  Retail: 'Retail',
  Warehouse: 'Warehouse',
  Automotive: 'Automotive',
}

export const propertyTypeLabels = [
  'SFR-Detached',
  'SFR-Attached',
  'Condo-Warrantable',
  'Condo-NonWarrantable',
  'Condotel',
  '2-4 Units',
  'PUD',
  'Mixed-Use',
  'Modular',
  'Multifamily 5+',
  'Industrial',
  'Manufactured',
  'Self Storage',
  'Office',
  'Retail',
  'Warehouse',
  'Automotive',
]

export const originalPropertyTypeLabels = [
  'SFR-Detached',
  'SFR-Attached',
  'Condo',
  '2-4 Units',
  'PUD',
  'Mixed-Use',
  'Modular',
  'Multifamily 5+',
  'Industrial',
  'Manufactured',
  'Self Storage',
  'Office',
  'Retail',
  'Warehouse',
  'Automotive',
]

export const condoTypeOptions: Record<string, string> = {
  Warrantable: 'Condo-Warrantable',
  NonWarrantable: 'Condo-NonWarrantable',
  Condotel: 'Condotel',
}

export const bankruptcyOptions: Record<string, string> = {
  None: 'None',
  BKActive: 'BK Active',
  BK7ZeroPlus: 'BK7 Discharged 1-11 Months',
  BK7OnePlus: 'BK7 Discharged 12-23 Months',
  BK7TwoPlus: 'BK7 Discharged 24-35 Months',
  BK7ThreePlus: 'BK7 Discharged 36-47 Months',
  BK7FourPlus: 'BK7 Discharged 48+ Months',
  BK13ZeroPlus: 'BK13 Discharged 1-11 Months',
  BK13OnePlus: 'BK13 Discharged 12-23 Months',
  BK13TwoPlus: 'BK13 Discharged 24-35 Months',
  BK13ThreePlus: 'BK13 Discharged 36-47 Months',
  BK13FourPlus: 'BK13 Discharged 48+ Months',
}

export const forbearanceOptions: string[] = ['None', '<=6 Months', '6-24 Months', '>24 Months']

export const yesNoOptions: string[] = ['Yes', 'No']

export const foreclosuresOptions: Record<string, string> = {
  None: 'None',
  ZeroPlus: '1-11 Months',
  OnePlus: '12-23 Months',
  TwoPlus: '24-35 Months',
  ThreePlus: '36-47 Months',
  FourPlus: '48+ Months',
}

export const mortgageLatesOptions: Record<string, string> = {
  NoOccurrences: '0X30X12',
  OneOccurrences30: '1X30X12',
  OneMoreOccurrences30: '0x60x12',
  OneOrMoreOccurrences60: '>= 1X60X12',
}

export const escrowTypesOptions = {
  notWaived: 'Not Waived',
  waiveBoth: 'Waive Both',
  waiveInsurance: 'Waive Insurance',
}

export const loanTermOptions = {
  6: '6 months',
  12: '12 months',
  18: '18 months',
  24: '24 months',
}

export const CommercialProdutTypes: Record<string, string> = {
  Bridge: 'Bridge',
  FixAndFlip: 'Fix & Flip / Fix to Rent',
  GroundUpConstruction: 'Ground Up Construction',
  DSCR: 'DSCR (Rental Loan)',
}

export const transactionTypeOptions = ['Purchase', 'Refinance']
