import { InputType } from 'config'

export const masterProgramDefaultInputs = (): Record<string, InputType> => {
  return {
    Name: {
      title: 'Master Program Name',
      inputType: 'text',
      error: '',
      required: true,
    },
    Level: {
      title: 'Price Options',
      inputType: 'select',
      hasDefaultOption: true,
      options: {},
      error: '',
      required: true,
    },
  }
}

export const hardMoneyInformations: Record<string, string[]> = {
  General: [
    'Foreign Nationals Allowed up to 65% LTV (price as 620 Fico)',
    'First Time Investors Eligible',
    'Purchase, Rate-Term Refinance, Cash-Out Refinance',
    'No Income Verification Eligible',
  ],
  'Credit Event & Housing Lates': [
    "Mortgage Rate = '0x60x12' then 5% LTV Reduction",
    "Mortgage Rate = '>= 1x60x12' then 10% LTV Reduction",
    "FC / SS / DIL if one of them is '2+ years' or '3+ years' or '4+ years' then 5% LTV Reduction",
    "Bankruptcy = '1+ years' and LoanPurpose = 'R/T Refi' then 5% LTV Reduction",
  ],
}

export const softMoneyInformations: Record<string, string[]> = {
  General: [
    'Foreign Nationals Allowed up to 65% LTV (price as 620 Fico)',
    '3 Year Pre Payment Penalty of 5% LTV Reduction',
    'First Time Investors Eligible',
    'Purchase, Rate-Term Refinance, Cash-Out Refinance',
    'Investment Eligible',
    'No Income Verification Eligible',
  ],
  'Credit Event & Housing Lates': [
    "Mortgage Rate = '0x60x12' then 5% LTV Reduction",
    "Mortgage Rate = '>= 1x60x12' then 10% LTV Reduction",
    "FC / SS / DIL if one of them is '2+ years' or '3+ years' or '4+ years' then 5% LTV Reduction",
    "Bankruptcy = '1+ years' and LoanPurpose = 'R/T Refi' then 5% LTV Reduction",
  ],
}

export const fastMoneyInformations: Record<string, string[]> = {
  'Investor Only': [
    'Foreign Nationals Allowed up to 65% LTV (price as 620 FICO if no Fico Exists)',
    'First Time Investors Eligible',
    'No Prepayment Penalty',
    'FICO Score Under 620 Subject to 10% Reduction in AIV-LTV and ARV-LTV',
    'Purchase, R/T Refi is Eligible',
    "Loan Purpose = 'Purchase' then 'Purchase-Fix and Flip' or 'Purchase Acquisition Only'",
    "Loan Purpose = 'R/T Refi' then 'Refinance Fix and Flip' or 'Refinance'",
    'No Income Verification Eligible',
  ],
  'Primary or Second Home': [
    'Foreign Nationals Not Allowed',
    'Purchase, Rate & Term, Cash Out Allowed',
    "Product Type should be 'bridge'",
    'No Income Verification Eligible',
  ],
  'Credit Event & Housing Lates': [
    "Mortgage Rate = '1x30x12' or '0x60x12' then 5% LTV Reduction",
    "Mortgage Rate = '>= 1x60x12' then 10% LTV Reduction",
    "FC / SS / DIL if one of them is '2+ years' or '3+ years' or '4+ years' then 5% LTV Reduction",
    "Bankruptcy = '1+ years' and LoanPurpose = 'R/T Refi' then 5% LTV Reduction",
    'When 80.01 <= LTC and LTC <= 85, -1% from Interest Rate',
    'When LTC < 80.01, -2% from Interest Rate',
    'Max LTC = 85.0%',
  ],
}

export const commercialDscrInformations: Record<string, string[]> = {
  General: [
    'Loan Amount < 250,000 then .5% rate addition',
    "Products: '30yr Fixed IO' and '30yr Fixed' (IO: 0.25 rate addition)",
    'Price reduction: 5 years: 0.00, 4 years: 0.375, 3 years: 0.75, 2 years: 1.125, 1 year: 1.5, 0 year: N/A',
    'Foreign Nationals Allowed up to 65% LTV (price as 620 Fico)',
    'Purchase, Rate-Term Refinance, Cash-Out Refinance',
    'NoIncomeVerification, DebtServiceCoverageRatio',
    'DSCR Required Min 1.20 on Multifamily, & Mixed-Use when Loan Amount > $500K',
    'DSCR Required Min 1.25 on traditional commercial when Loan Amount > $500K',
    'No Min DSCR when loan amount <= $500,000',
    'DSCR on Owner Occupied minimum is 1.00 on all transactions except no min DSCR is required for refi trans with loan amounts < or = $500K or purchase transaction with loan amounts < or = $750,000',
  ],
  'Credit Event & Housing Lates': [
    "Mortgage Rate = '0x60x12' then 5% LTV Reduction",
    "Mortgage Rate = '>= 1x60x12' then 10% LTV Reduction",
    "FC / SS / DIL if one of them is '2+ years' or '3+ years' or '4+ years' then 5% LTV Reduction",
    "Bankruptcy = 'BK7 Discharged 12-23 Months' and LoanPurpose = 'R/T Refi' then 5% LTV Reduction",
    'First Time Investor = Yes, 5% LTV Reduction',
  ],
}

export const productTransaction = {
  Commercial_Bridge_Purchase: {
    label: 'Commercial Bridge Purchase',
    code: `Limits = {AIV-LTV: 70, LTC: 70, LTP: 70}
LoanAmount = {200,000 - 5,000,000}
===== Min Fico: 620 =====
===== Reduction Logic =====
Fico | 550-579 | AIV-LTV - 15%, ARV-LTV - 10%
Fico | 580-599 | AIV-LTV - 10%, ARV-LTV - 10%
Fico | 600-619 | AIV-LTV - 5%, ARV-LTV - 5%

===== Interest Rate =====
let add = 0;
if (experience === "0 Transaction") add = 1;
if (experience === "1 Transaction") add = 0.75;
if (experience === "2 Transactions") add = 0.5;

if (background === 'Felony <= 15 Years') add += 1

if (loanTerm === 18) add += 0.25
if (loanTerm === 24) add += 0.5

if (loanAmount < 200,000) return 0;

if (loanAmount >= 200,000 && loanAmount <= 5,000,000) {
  if (ltc > 85 && ltc <= 90) return 13.74 + add;
  if (ltc > 80 && ltc <= 85) return 13.24 + add;
  if (ltc <= 80) return 12.99 + add;
}`,
  },
  Commercial_Bridge_Refinance: {
    label: 'Commercial Bridge Refinance',
    code: `Limits = {AIV-LTV: 65}
LoanAmount = {200,000 - 5,000,000}
===== Min Fico: 620 =====
===== Reduction Logic =====
Fico | 550-579 | AIV-LTV - 15%, ARV-LTV - 10%
Fico | 580-599 | AIV-LTV - 10%, ARV-LTV - 10%
Fico | 600-619 | AIV-LTV - 5%, ARV-LTV - 5%

===== Interest Rate =====
let add = 0;
if (experience === "0 Transaction") add = 1;
if (experience === "1 Transaction") add = 0.75;
if (experience === "2 Transactions") add = 0.5;

if (background === 'Felony <= 15 Years') add += 1

if (loanTerm === 18) add += 0.25
if (loanTerm === 24) add += 0.5
  
if (loanAmount < 200,000) return 0;

if (loanAmount >= 200,000 && loanAmount <= 5,000,000) {
  let credit_add = 0;
  if (credit >= 550 && credit < 580) credit_add = 2;
  if (credit >= 580 && credit < 620) credit_add = 1;
  return 12.99 + credit_add + add;
}`,
  },
  SFR_Bridge_Purchase: {
    label: 'SFR Bridge Purchase',
    code: `Limits = {AIV-LTV: 70, LTC: 70, LTP: 75}
LoanAmount = {200,000 - 5,000,000}
===== Min Fico: 550 =====
===== Reduction Logic =====
Fico | 550-579 | AIV-LTV - 15%, ARV-LTV - 10%
Fico | 580-599 | AIV-LTV - 10%, ARV-LTV - 10%
Fico | 600-619 | AIV-LTV - 5%, ARV-LTV - 5%

===== Interest Rate =====
let add = 0;
if (experience === "0 Transaction") add = 1;
if (experience === "1 Transaction") add = 0.75;
if (experience === "2 Transactions") add = 0.5;

if (background === 'Felony <= 15 Years') add += 1

if (loanTerm === 18) add += 0.5

if (loanAmount < 200,000) return 0;

if (loanAmount >= 200,000 && loanAmount <= 5,000,000) {
  let credit_add = 0;
  if (credit >= 550 && credit < 580) credit_add = 2;
  if (credit >= 580 && credit < 620) credit_add = 1;
  return 12.49 + credit_add + add;
}`,
  },
  SFR_Bridge_Refinance: {
    label: 'SFR Bridge Refinance',
    code: `Limits = {AIV-LTV: 65}
LoanAmount = {200,000 - 5,000,000}
===== Min Fico: 550 =====
===== Reduction Logic =====
Fico | 550-579 | AIV-LTV - 15%, ARV-LTV - 10%
Fico | 580-599 | AIV-LTV - 10%, ARV-LTV - 10%
Fico | 600-619 | AIV-LTV - 5%, ARV-LTV - 5%

===== Interest Rate =====
let add = 0;
if (experience === "0 Transaction") add = 1;
if (experience === "1 Transaction") add = 0.75;
if (experience === "2 Transactions") add = 0.5;

if (background === 'Felony <= 15 Years') add += 1

if (loanTerm === 18) add += 0.5

if (loanAmount < 200,000) return 0;

if (loanAmount >= 200,000 && loanAmount <= 5000000) {
  if (aiv_ltv <= 70) return 12.49 + add;
  if (aiv_ltv <= 75) return 12.49 + add;
  if (aiv_ltv > 80) return 0;
  if (aiv_ltv > 75) return 12.49 + add;
  return 0;
}`,
  },
  Swifty50_Purchase: {
    label: 'Swifty50 Purchase',
    code: `
`,
  },
  Swifty50_Refinance: {
    label: 'Swifty50 Refinance',
    code: `Limits = {AIV-LTV: 50}
LoanAmount = {200,000 - 5,000,000}
===== Min Fico: 620 =====
===== Reduction Logic =====
Fico | 550-579 | AIV-LTV - 15%, ARV-LTV - 10%
Fico | 580-599 | AIV-LTV - 10%, ARV-LTV - 10%
Fico | 600-619 | AIV-LTV - 5%, ARV-LTV - 5%

===== Interest Rate =====
let add = 0;
if (experience === "0 Transaction") add = 1;
if (experience === "1 Transaction") add = 0.75;
if (experience === "2 Transactions") add = 0.5;

if (background === 'Felony <= 15 Years') add += 1

if (loanTerm === 18) add += 0.5

if (loanAmount < 200,000) return 0;

if (loanAmount >= 200,000 && loanAmount <= 5000000) {
  if (aiv_ltv <= 70) return 13.49 + add;
  if (aiv_ltv <= 75) return 13.49 + add;
  if (aiv_ltv > 80) return 0;
  if (aiv_ltv > 75) return 13.49 + add;
  return 0;
}`,
  },
  Commercial_Rehab_and_Construction_Purchase: {
    label: 'Commercial Rehab and Construction Purchase',
    code: `Limits = {AIV-LTV: 70, ARV-LTV: 65, LTC: 80, LTP: 75}
LoanAmount = {200,000 - 5,000,000}
===== Min Fico: 620 =====
===== Reduction Logic =====
Fico | 550-579 | AIV-LTV - 15%, ARV-LTV - 10%
Fico | 580-599 | AIV-LTV - 10%, ARV-LTV - 10%
Fico | 600-619 | AIV-LTV - 5%, ARV-LTV - 5%

===== Interest Rate =====
let add = 0;
if (experience === "0 Transaction") add = 1;
if (experience === "1 Transaction") add = 0.75;
if (experience === "2 Transactions") add = 0.5;

if (background === 'Felony <= 15 Years') add += 1

if (loanTerm === 18) add += 0.25
if (loanTerm === 24) add += 0.5  

if (loanAmount < 200,000) return 0;

if (loanAmount >= 200,000 && loanAmount <= 5,000,000) {
  if (ltc > 85 && ltc <= 90) return 13.74 + add;
  if (ltc > 80 && ltc <= 85) return 13.24 + add;
  if (ltc <= 80) return 12.99 + add;
}`,
  },
  Commercial_Rehab_and_Construction_Refinance: {
    label: 'Commercial Rehab and Construction Refinance',
    code: `Limits = {AIV-LTV: 70, ARV-LTV: 65, LTC: 80}
LoanAmount = {200,000 - 5,000,000}
===== Min Fico: 620 =====
===== Reduction Logic =====
Fico | 550-579 | AIV-LTV - 15%, ARV-LTV - 10%
Fico | 580-599 | AIV-LTV - 10%, ARV-LTV - 10%
Fico | 600-619 | AIV-LTV - 5%, ARV-LTV - 5%

===== Interest Rate =====
let add = 0;
if (experience === "0 Transaction") add = 1;
if (experience === "1 Transaction") add = 0.75;
if (experience === "2 Transactions") add = 0.5;

if (background === 'Felony <= 15 Years') add += 1

if (loanTerm === 18) add += 0.25
if (loanTerm === 24) add += 0.5
  
if (loanAmount < 200,000) return 0;

if (loanAmount >= 200,000 && loanAmount <= 5000000) {
  if (ltc <= 80) return 12.99 + add;
  if (ltc <= 85) return 13.24 + add;
  if (ltc > 90) return 0;
  if (ltc > 85) return 13.74 + add;
  return 0;
}`,
  },
  Ground_Up_Construction_Purchase: {
    label: 'Ground Up Construction Purchase',
    code: `Limits = {AIV-LTV: 50, ARV-LTV: 65, LTC: 85, LTP: 85}
LoanAmount = {200,000 - 5,000,000}
===== Min Fico: 550 =====
===== Reduction Logic =====
Fico | 550-579 | AIV-LTV - 15%, ARV-LTV - 10%
Fico | 580-599 | AIV-LTV - 10%, ARV-LTV - 10%
Fico | 600-619 | AIV-LTV - 5%, ARV-LTV - 5%

===== Interest Rate =====
let add = 0;
if (experience === "0 Transaction") add = 1;
if (experience === "1 Transaction") add = 0.75;
if (experience === "2 Transactions") add = 0.5;

if (background === 'Felony <= 15 Years') add += 1

if (loanTerm === 18) add += 0.5

if (loanAmount < 200,000) return 0;

if (loanAmount >= 200,000 && loanAmount <= 5,000,000) {
  if (ltc <= 80) return 12.74 + add;
  if (ltc <= 85) return 12.99 + add;
  if (ltc > 90) return 0;
  if (ltc > 85) return 0;
}`,
  },
  Ground_Up_Construction_Refinance: {
    label: 'Ground Up Construction Refinance',
    code: `Limits = {AIV-LTV: 50, ARV-LTV: 65, LTC: 85}
LoanAmount = {200,000 - 5,000,000}
===== Min Fico: 550 =====
===== Reduction Logic =====
Fico | 550-579 | AIV-LTV - 15%, ARV-LTV - 10%
Fico | 580-599 | AIV-LTV - 10%, ARV-LTV - 10%
Fico | 600-619 | AIV-LTV - 5%, ARV-LTV - 5%

===== Interest Rate =====
let add = 0;
if (experience === "0 Transaction") add = 1;
if (experience === "1 Transaction") add = 0.75;
if (experience === "2 Transactions") add = 0.5;

if (background === 'Felony <= 15 Years') add += 1

if (loanTerm === 18) add += 0.5

if (loanAmount < 200,000) return 0;

if (loanAmount >= 200,000 && loanAmount <= 5,000,000) {
  if (ltc <= 80) return 12.74 + add;
  if (ltc <= 85) return 12.99 + add;
  if (ltc > 90) return 0;
  if (ltc > 85) return 0;
}`,
  },
  Residential_Construction_Purchase: {
    label: 'Residential Construction & Fix and Flip Purchase',
    code: `Limits = {AIV-LTV: 80, ARV-LTV: 65, LTC: 90, LTP: 85}
LoanAmount = {200,000 - 5,000,000}
===== Min Fico: 550 =====
===== Reduction Logic =====
Fico | 550-579 | AIV-LTV - 15%, ARV-LTV - 10%
Fico | 580-599 | AIV-LTV - 10%, ARV-LTV - 10%
Fico | 600-619 | AIV-LTV - 5%, ARV-LTV - 5%

===== Interest Rate =====
let add = 0;
if (experience === "0 Transaction") add = 1;
if (experience === "1 Transaction") add = 0.75;
if (experience === "2 Transactions") add = 0.5;

if (background === 'Felony <= 15 Years') add += 1

if (loanTerm === 18) add += 0.5

if (loanAmount < 200,000) return 0;

if (loanAmount >= 200,000 && loanAmount <= 5,000,000) {
  if (ltc <= 80) return 12.49 + add;
  if (ltc > 80 && ltc <= 85) return 12.74 + add;
  if (ltc > 85 && ltc <= 90) return 13.24 + add;
  if (ltc > 90) return 0;
  return 0;
}`,
  },
  Residential_Construction_Refinance: {
    label: 'Residential Construction & Fix and Flip Refinance',
    code: `Limits = {AIV-LTV: 75, ARV-LTV: 65, LTC: 90}
LoanAmount = {200,000 - 5,000,000}
===== Min Fico: 550 =====
===== Reduction Logic =====
Fico | 550-579 | AIV-LTV - 15%, ARV-LTV - 10%
Fico | 580-599 | AIV-LTV - 10%, ARV-LTV - 10%
Fico | 600-619 | AIV-LTV - 5%, ARV-LTV - 5%

===== Interest Rate =====
let add = 0;
if (experience === "0 Transaction") add = 1;
if (experience === "1 Transaction") add = 0.75;
if (experience === "2 Transactions") add = 0.5;

if (background === 'Felony <= 15 Years') add += 1

if (loanTerm === 18) add += 0.5

if (loanAmount < 200,000) return 0;

if (loanAmount >= 200,000 && loanAmount <= 5,000,000) {
  if (ltc <= 80) return 12.49 + add;
  if (ltc > 80 && ltc <= 85) return 12.74 + add;
  if (ltc > 85 && ltc <= 90) return 13.24 + add;
  if (ltc > 90) return 0;
  return 0;
}`,
  },
}

export const calcLogic = {
  label: 'AIV-LTV, ARV-LTV, LTC, LTP Calculation Logic',
  code: `AIV-LTV = 100 * (Proposed Loan Amount - Construction Amount Financed) / Min(Property Purchase Price, As Is Appraised Value)
ARV-LTV = 100 * (Proposed Loan Amount) /  After Repair Value
LTC     = 100 * (Proposed Loan Amount) /  (Min(Property Purchase Price, As Is Appraised Value) + Construction Budget)
LTP     = 100 * (Proposed Loan Amount - Construction Amount Financed) / Property Purchase Price`,
}

export const OrderOptions = {
  asc: 'Ascending',
  desc: 'Descending',
}
