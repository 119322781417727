import 'prismjs/themes/prism.css'

import { PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type {
  ICityCountyTier,
  ICommercialOnlyProgrammed,
  IExperienceTier,
  IExperienceTierLimit,
  ILoanPurposeLTVValues,
  IMsaTier,
  IProgram,
  IPropertyTypeLtvAdj,
  IRange,
  IRatePriceAdjustment,
  IStateMSA,
  IStateTier,
} from 'config'
import { InvestorInfo } from 'pages/RateSheetOverview/InvestorInfo'
import { LoanProgram } from 'pages/RateSheetOverview/LoanProgram'
import { ProgramMargin } from 'pages/RateSheetOverview/PriceMargin/ProgramMargin'
import Prism from 'prismjs'
import { useEffect, useMemo, useState } from 'react'
import Editor from 'react-simple-code-editor'
import { toast } from 'react-toastify'
import excelService from 'services/excelService'
import Sheet, { Style } from 'sheet-happens'
import { Button, Select } from 'stories/components'
import { getPrice3decimal, isEmpty, solveDecimalJavascriptSum } from 'utils'

import { LoadingStatus, ProgramTypes } from '../..'
import { calcLogic, productTransaction } from '../../constants'
import { CityCountyTiers } from './CityCountyTiers'
import { ExperienceTiers } from './ExperienceTiers'
import { LeverageAdjustments } from './LeverageAdjustments'
import { LeverageLimits } from './LeverageLimits'
import { getTitleAndIndex } from './logic'
import { RatePriceRangeEditModal } from './Modal/RatePriceRangeEditModal'
import { MsaTiers } from './MsaTiers/MsaTiers'

const { highlight, languages } = Prism

interface Props {
  loanId: number
  companyID: number
  program: IProgram
  tiersData: IStateTier
  loading: LoadingStatus
  isTemplate: boolean
  isGeneralRatesheet: boolean
  onSave: (data: Record<string, any>) => {}
  onAddTier: (tierName: string, key: string, tierKey?: string) => Promise<boolean>
  onRemoveTier: (tierName: string, key: string, tierKey?: string) => Promise<boolean>
  onUpdateTier: (originalName: string, tierName: string, key: string, tierKey?: string) => Promise<boolean>
  onSaveTierValues: (
    tierName: string,
    key: string,
    state: string,
    values: string[] | IStateMSA[],
    tierKey?: string,
  ) => Promise<boolean>
  onSaveLtvAdj: (tierName: string, key: string, ltvAdj: ILoanPurposeLTVValues) => Promise<boolean>
  onSaveMsaLtvAdj: (tierName: string, ltvAdj: IPropertyTypeLtvAdj[]) => Promise<boolean>
  onRemoveMsaLtvAdjCondition: (tierName: string, index: number) => void
  onSaveLtvRatePriceRange: (name: string, range: IRange[], ratePriceAdj: IRatePriceAdjustment) => Promise<boolean>
  onSyncTiers: (modalName: string) => void
  onChangeExperienceLimit: (tierKey: string, tierName: string, limit: IExperienceTierLimit[]) => void
  onChangeNonDscrLeverageData: (data: any[], limitKey: string, type: string) => void
  onSaveBonusMargin: (data: any, key: string) => void
  onSaveProgramInfo: (data: any, key: string) => {}
}

interface ICommercialProgramParseRlt {
  sheet: any[]
  titles: string[]
  editables: string[]
  rightAlignCells: string[]
  rows: Record<string, number[] | number>
}

export const LtvRatePriceRangeNames = {
  aivLTV: 'AIV-LTV',
  LTC: 'LTC',
  fico: 'FICO',
  reserved: 'Months Reserve',
}

export const menu = {
  investorInfo: 'Investor Info',
  margin: 'Price Margin / Rate & Price Limit',
  cityTier: 'City Tiers',
  countyTier: 'County Tiers',
  msaTier: 'MSA Tiers',
  experienceTier: 'Experience Tiers',
  leverageLimits: 'Leverage Limits',
  leverageAdjustments: 'Leverage Adjustments',
  loanProgram: 'Loan Programs',
}

export function CommercialOnlyProgrammed({
  loanId,
  companyID,
  program,
  tiersData,
  loading,
  isTemplate,
  isGeneralRatesheet,
  onSave,
  onSaveLtvRatePriceRange,
  onAddTier,
  onRemoveTier,
  onUpdateTier,
  onSaveLtvAdj,
  onSaveMsaLtvAdj,
  onSaveTierValues,
  onRemoveMsaLtvAdjCondition,
  onChangeExperienceLimit,
  onChangeNonDscrLeverageData,
  onSaveBonusMargin,
  onSaveProgramInfo,
}: Props) {
  const [edit, setEdit] = useState<Record<string, number>>({})
  const [data, setData] = useState<any[][]>([[], []])
  const [editable, setEditable] = useState<string[]>([])
  const [titles, setTitles] = useState<string[]>([])
  const [rightAlignCells, setRightAlignCells] = useState<string[]>([])
  const [cellWidth, setCellWidth] = useState<number[]>([])
  const [cellHeight, setCellHeight] = useState<number[]>([])
  const [baseRates, setRates] = useState<number[]>()
  const [rows, setRows] = useState<Record<string, number[] | number>>({})
  const [modal, setModal] = useState<string>('')

  const [codes, setCodes] = useState<Record<string, any>>(productTransaction)
  const [logic, setLogic] = useState<Record<string, any>>(calcLogic)

  const [selectedMenu, setSelectedMenu] = useState<string>(menu.investorInfo)
  const [selectedTier, setSelectedTier] = useState<ICityCountyTier[] | IMsaTier[] | IExperienceTier>([])
  const [priceDisplay, setPriceDisplay] = useState('Price')

  const onChangeCode = (key: string, value: string) => {
    const newCodes = cloneDeep(codes)
    newCodes[key].code = value
    setCodes(newCodes)
  }

  const columnHeaders = ['A', 'B', 'C']

  const loanAmountCols = [1, 2]
  const stackCols = [8, 9, 10, 11]

  const isPoints = useMemo(() => {
    return priceDisplay === 'Points'
  }, [priceDisplay])

  useEffect(() => {
    if (!program?.OtherTypeProgramData) return
    ;(async () => {
      const programData = cloneDeep(program.OtherTypeProgramData as ICommercialOnlyProgrammed)
      if (isPoints) {
        programData.basePrices = programData.basePrices.map((item) =>
          solveDecimalJavascriptSum([100, -1 * Number(item)]),
        )
      }
      let { sheet, titles, editables, rightAlignCells, rows } = (await excelService.parseNonDscrProgram(
        programData,
        isPoints,
      )) as ICommercialProgramParseRlt

      setData(sheet)
      setEdit({})

      const cellWidth = [220, 120, 115, 120, 115, 110, 115, 30, 90, 90, 90, 90]
      setCellWidth(cellWidth)

      setEditable(editables)
      setTitles(titles)
      setRightAlignCells(rightAlignCells)
      setRows(rows)
      setRates(programData.baseRates)
    })()
  }, [program, isPoints])

  useEffect(() => {
    switch (selectedMenu) {
      case menu.cityTier:
        setSelectedTier(tiersData.cityTiers)
        break
      case menu.countyTier:
        setSelectedTier(tiersData.countyTiers)
        break
      case menu.msaTier:
        setSelectedTier(tiersData.msaTiers)
        break
      case menu.experienceTier:
        setSelectedTier(tiersData.experienceTiers)
        break
      default:
        setSelectedTier([])
        break
    }
  }, [selectedMenu, tiersData])

  const onSelectionChanged = (x1: number, y1: number, x2: number, y2: number) => {
    console.log('track change', x1, y1, x2, y2)
    return {}
  }

  const cellStyle = (x: number, y: number) => {
    let rlt = {
      textAlign: 'left',
      fillColor: '',
      weight: '',
    }

    if (titles.includes(`${y}-${x}`)) {
      if ([1, 6, rows.rateRowStart, rows.rateRowEnd].includes(y)) {
        rlt.fillColor = '#FFAB756B'
        rlt.weight = 'bold'
      } else {
        rlt.fillColor = '#6DA2FB22'
        rlt.weight = 'bold'
      }

      if (x > 0) rlt.textAlign = 'center'
    }

    if (rightAlignCells.includes(`${y}-${x}`)) rlt.textAlign = 'right'

    if (editable.includes(`${y}-${x}`)) {
      if (y > (rows.rateRowStart as number) && y < (rows.rateRowEnd as number) && x < 7 && x > 0) {
        if ([1, 2].includes(x)) {
          rlt.fillColor = '#f7f9fa'
          rlt.textAlign = 'center'
        } else if ([3, 4].includes(x)) {
          rlt.fillColor = '#ecf9f2'
          rlt.textAlign = 'center'
        } else if ([5, 6].includes(x)) {
          rlt.fillColor = '#fffae6'
          rlt.textAlign = 'center'
        }
      } else {
        rlt.fillColor = '#f7f9fa'
        rlt.textAlign = 'center'
      }
    }

    if (rows?.centerTitleRows && Array.isArray(rows.centerTitleRows))
      if (rows.centerTitleRows.includes(y) && x === 0) {
        rlt.textAlign = 'center'
        rlt.weight = 'bold'
      }

    return rlt
  }

  const editData = (x: number, y: number) => {
    return data?.[y]?.[x]
  }

  const displayData = (x: number, y: number) => {
    let rlt = data?.[y]?.[x]

    if (editable.includes(`${y}-${x}`))
      if (y > program.OtherTypeProgramData.baseRates.length && stackCols.includes(x)) {
      } else rlt = isEmpty(rlt) ? 'N/A' : rlt

    if (y === 0 && loanAmountCols.includes(x)) {
      rlt = rlt === 'N/A' ? rlt : '$ ' + getPrice3decimal(rlt)
    }

    return rlt
  }

  const sourceData = (x: number, y: number) => {
    return data?.[y]?.[x]
  }

  const onChange = (changes: any[]) => {
    if (!baseRates) return

    let newData = [...data]
    let newEdit: Record<string, number> = { ...edit }

    for (const change of changes) {
      let { x, y, value } = change

      if (newData[y][x] === value || (newData[y][x] === null && value === '')) continue

      if (value === '') value = ''
      else value = isNaN(Number(value)) ? '' : Number(Number(value).toFixed(3))

      if (editable.includes(`${y}-${x}`) || (stackCols.includes(x) && y !== 0)) {
        const res = getTitleAndIndex(x, y, rows as Record<string, number[]>)

        if (res.title === 'maxLtvAdjustment' && value > 0) {
          toast('Max LTV Adjustment must be negative', { type: 'error' })
          continue
        }

        if (res.title === 'maxRatePriceAdjustment' && value < 0) {
          toast('Max Rate & Price Adjustment must be positive', { type: 'error' })
          continue
        }

        if (['aivLtvs', 'ltcs'].includes(res.title))
          if (
            (res.index === 0 && Number(value) <= Number(newData[y + 1][x])) ||
            (res.index === 1 &&
              (Number(value) >= Number(newData[y - 1][x]) || Number(value) <= Number(newData[y + 1][x]))) ||
            (res.index === 2 && Number(value) >= Number(newData[y - 1][x]))
          ) {
            toast('Invalid value', { type: 'error' })
            continue
          }

        newData[y][x] = value
        newEdit[`${res.title}-${res.index}-${x === 0 ? NaN : x - 1}`] =
          res.title === 'basePrices' && isPoints ? solveDecimalJavascriptSum([100, -1 * Number(value)]) : value
      }
    }

    setEdit(newEdit)
    setData(newData)
  }

  const isReadOnly = (x: number, y: number) => {
    if (editable.indexOf(`${y}-${x}`) === -1) return true
    else return false
  }

  const onCellWidthChange = (columnIdx: any, newWidth: number) => {
    const cw: number[] = [...cellWidth]
    if (columnIdx > cw.length) {
      for (let i = cw.length; i <= columnIdx; i++) {
        cw.push(100)
      }
    }
    cw[columnIdx] = newWidth
    setCellWidth(cw)
  }

  const onCellHeightChange = (rowIdx: any, newHeight: number) => {
    const ch: number[] = [...cellHeight]
    if (rowIdx > ch.length) {
      for (let i = ch.length; i <= rowIdx; i++) {
        ch.push(22)
      }
    }
    ch[rowIdx] = newHeight
    setCellHeight(ch)
  }

  const renderTiers = useMemo(() => {
    if (!program) return <></>

    switch (selectedMenu) {
      case menu.investorInfo:
        return <InvestorInfo program={program} loading={loading} onSave={onSaveProgramInfo} />
      case menu.margin:
        return (
          <ProgramMargin
            program={program}
            loading={loading}
            onSaveBonusMargin={onSaveBonusMargin}
            onSavePriceMarginLimit={onSaveProgramInfo}
          />
        )
      case menu.cityTier:
      case menu.countyTier:
        return (
          <CityCountyTiers
            isTemplate={isTemplate}
            title={selectedMenu}
            loading={loading}
            isCommercial={program.Type === ProgramTypes.COMMERCIAL_ONLY_PROGRAMMED}
            tiers={selectedTier as ICityCountyTier[]}
            onAdd={onAddTier}
            onRemove={onRemoveTier}
            onUpdate={onUpdateTier}
            onSaveTierValues={onSaveTierValues}
            onSaveLtvAdj={onSaveLtvAdj}
          />
        )
      case menu.msaTier:
        return (
          <MsaTiers
            isTemplate={isTemplate}
            loading={loading}
            tiers={selectedTier as IMsaTier[]}
            isCommercial={program.Type === ProgramTypes.COMMERCIAL_ONLY_PROGRAMMED}
            onAdd={onAddTier}
            onRemove={onRemoveTier}
            onUpdateTier={onUpdateTier}
            onSaveTierValues={onSaveTierValues}
            onSaveLtvAdj={onSaveMsaLtvAdj}
            onRemoveLtvAdjCondition={onRemoveMsaLtvAdjCondition}
          />
        )
      case menu.experienceTier:
        return (
          <ExperienceTiers
            isTemplate={isTemplate}
            maxLoanAmount={(program.OtherTypeProgramData as ICommercialOnlyProgrammed).loanAmount.to}
            title={selectedMenu}
            loading={loading}
            tierData={selectedTier as IExperienceTier}
            onAdd={onAddTier}
            onRemove={onRemoveTier}
            onUpdate={onUpdateTier}
            onSaveTierValues={onSaveTierValues}
            onChangeExperienceLimit={onChangeExperienceLimit}
          />
        )
      case menu.leverageLimits:
        return (
          <LeverageLimits
            program={program}
            loanId={loanId}
            title={selectedMenu}
            isTemplate={isTemplate}
            loading={loading}
            onChangeNonDscrLeverageData={onChangeNonDscrLeverageData}
          />
        )
      case menu.leverageAdjustments:
        return (
          <LeverageAdjustments
            program={program}
            loanId={loanId}
            title={selectedMenu}
            isTemplate={isTemplate}
            loading={loading}
            onChangeNonDscrLeverageData={onChangeNonDscrLeverageData}
          />
        )
      case menu.loanProgram:
        return <LoanProgram companyID={companyID} program={program} />
      default:
        return <></>
    }
  }, [selectedTier, selectedMenu, loading])

  return (
    <div className="CommercialOnlyProgrammed-container py-6 mb-5">
      {program?.OtherTypeProgramData ? (
        <div className="relative max-w-screen-2xl m-auto w-full">
          {/* {isTemplate && ( */}
          <div className="grid grid-cols-12 gap-6 mb-8">
            <div className="col-span-12 md:col-span-3 shrink-0 p-4 bg-white shadow1 rounded">
              <ul className="flex flex-col">
                {Object.keys(menu).map((key, index) => {
                  if (isGeneralRatesheet && key === 'loanProgram') return <></>
                  const item = (menu as any)[key]
                  return (
                    <li key={index} onClick={() => setSelectedMenu(item)} className="border-b py-2">
                      <p
                        className={`hover:underline cursor-pointer ${
                          selectedMenu === item ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                        }`}
                      >
                        {index + 1 + '. ' + item}
                      </p>
                    </li>
                  )
                })}
              </ul>

              {/* {isGeneralRatesheet && (
                  <Button
                    className="text-[16px] mt-4 w-full pt-1.5 pb-1.5"
                    onClick={() => onSyncTiers(ModalSetting.SYNC_TIERS)}
                  >
                    <span className="flex justify-center items-center">
                      <ArrowPathIcon className="w-4 h-4 mr-1" />
                      <span>Sync</span>
                    </span>
                  </Button>
                )} */}
            </div>
            <div className="relative col-span-12 md:col-span-9 shrink-0 p-4 bg-white shadow1 rounded">
              {renderTiers}
            </div>
          </div>
          {/* )} */}

          <div className="flex justify-center mb-8">
            <div className="shadow1 pt-4 pb-3 px-5 rounded w-fit">
              <div className="font-variation-settings-600 mb-2">{calcLogic.label}</div>
              <pre className="whitespace-pre-wrap border border-slate-500 rounded py-2 px-3">{calcLogic.code}</pre>

              <hr className="mt-5 mb-3" />

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                <div>
                  <div className="font-variation-settings-600 mb-2">Rate & Price Adjustments</div>
                  <div className="flex items-center gap-4 h-6">
                    {Object.values(LtvRatePriceRangeNames).map((key, index) => (
                      <div
                        key={index}
                        className="flex items-center text-sm hover:cursor-pointer text-shade-blue hover:border-b hover:border-b-shade-blue transition-all duration-200 pb-1"
                        onClick={() => setModal(key)}
                      >
                        <PencilSquareIcon className="w-4 h-4 mr-1" />
                        <span>{key}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <div className="font-variation-settings-600 mb-2">Price Display</div>
                  <Select
                    id="priceDisplay"
                    className="w-24 -mb-4"
                    options={['Price', 'Points']}
                    value={priceDisplay}
                    onChange={(value: any) => setPriceDisplay(value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {program && Object.keys(edit).length > 0 && (
            <div className="flex justify-end">
              <Button
                loading={loading === LoadingStatus.SAVE_PROGRAM_CHANGES}
                onClick={async () => {
                  await onSave(edit)
                  setEdit({})
                }}
              >
                Save
              </Button>
            </div>
          )}

          <Sheet
            onSelectionChanged={onSelectionChanged}
            onRightClick={() => {}}
            columnHeaders={columnHeaders}
            cellStyle={cellStyle as Style}
            editData={editData}
            displayData={displayData}
            sourceData={sourceData}
            cellWidth={cellWidth}
            cellHeight={cellHeight}
            onChange={onChange}
            readOnly={isReadOnly}
            onCellWidthChange={onCellWidthChange}
            onCellHeightChange={onCellHeightChange}
            sheetStyle={{ freezeColumns: 1, freezeRows: 8 }}
          />
        </div>
      ) : (
        <div className="relative max-w-screen-xl m-auto w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-9">
            <div className="col-span-2">
              <p className="font-variation-settings-600 mb-2">{logic.label}</p>

              <Editor
                value={logic.code}
                onValueChange={(value) => setLogic({ ...logic, code: value })}
                highlight={(code) => highlight(code, languages.js, 'js')}
                padding={10}
                className="box code-editor"
              />
            </div>

            {Object.keys(codes).map((key: string, index: number) => {
              const { label, code } = codes[key]

              return (
                <div className="col-span-2 md:col-span-1 my-2" key={index}>
                  <div className="font-variation-settings-600 mb-2">{label}</div>
                  <Editor
                    value={code}
                    onValueChange={(value) => onChangeCode(key, value)}
                    highlight={(code) => highlight(code, languages.js, 'js')}
                    padding={10}
                    className="box code-editor"
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}

      {Object.values(LtvRatePriceRangeNames).includes(modal) && (
        <RatePriceRangeEditModal
          programData={program.OtherTypeProgramData as ICommercialOnlyProgrammed}
          loading={loading}
          name={modal}
          step={[LtvRatePriceRangeNames.LTC, LtvRatePriceRangeNames.aivLTV].includes(modal) ? 0.01 : 1}
          onClose={() => setModal('')}
          onSubmit={async (name, range, ratePriceAdj) => {
            const res = await onSaveLtvRatePriceRange(name, range, ratePriceAdj)

            if (res) setModal('')
          }}
        />
      )}
    </div>
  )
}
