import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import type { IMatrixLTVData, IProgram, IRange } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import {
  convertNullToBlank,
  convertNullToNoScore,
  convertNullToPlus,
  convertNullValue,
} from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, ButtonGroup, Select } from 'stories/components'
import { confirm, getPrice3decimal, isEmpty, removeComma, solveDecimalJavascriptSum } from 'utils'

import { OrderOptions } from './constants'
import { AddNewFICOModal } from './Modals'

interface IProps {
  program: IProgram
  loading: string
  onSave: (data: IMatrixLTVData, key: string) => {}
}

export function MatrixLTVTable({ program, loading, onSave }: IProps) {
  const [data, setData] = useState<IMatrixLTVData>()
  const [menus, setMenus] = useState<string[]>([])
  const [selectedMenu, setSelectedMenu] = useState('')
  const [restrictionKey, setRestrictionKey] = useState('')
  const [edit, setEdit] = useState(false)
  const [addFICO, setAddFICO] = useState(false)
  const [targetIndex, setTargetIndex] = useState({ index: -1, idx: -1 })
  const [order, setOrder] = useState(OrderOptions.asc)

  const isDSCR = useMemo(() => {
    return restrictionKey === 'DSCR'
  }, [restrictionKey])

  useEffect(() => {
    const newMenus: string[] = []
    program?.Restriction?.MatrixLTVData?.forEach((item) => newMenus.push(item.label))
    const targetData = program.Restriction?.MatrixLTVData ? program.Restriction?.MatrixLTVData[0] : undefined
    if (!!targetData && targetData?.key === 'DSCR') {
      if (targetData.maxLTVs.length < 2) setOrder(OrderOptions.asc)
      else if ((targetData.maxLTVs[0].DSCR as IRange)?.from > (targetData.maxLTVs[1].DSCR as IRange)?.from)
        setOrder(OrderOptions.desc)
    }

    setData(targetData)
    setRestrictionKey(targetData?.key || '')
    setMenus(newMenus)
    setSelectedMenu(!!newMenus.length ? newMenus[0] : '')
  }, [program])

  useEffect(() => {
    setEdit(false)
  }, [restrictionKey])

  const onSelectMenu = (lable: string) => {
    const targetData = program.Restriction?.MatrixLTVData?.find((item) => item.label === lable)
    setData(targetData)
    setRestrictionKey(targetData?.key || '')
    setSelectedMenu(lable)
  }

  const onChangeMinMonthsReserve = (index: number, idx: number, value: any) => {
    if (!data) return
    value = removeComma(value)
    const newData = cloneDeep(data)
    if (data.maxLTVs[index].data[idx].monthsReserve === value) return
    newData.maxLTVs[index].data[idx].monthsReserve = value
    setData(newData)
    setEdit(true)
  }

  const onChangeFICO = (index: number, idx: number, id: number, value: any) => {
    if (!data) return
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    const isLast = data.maxLTVs[index].data[idx].ficos.length - 1 === id
    const isFirst = id === 0
    const preValue = data.maxLTVs[index].data[idx].ficos[id - 1]
    const nextValue = data.maxLTVs[index].data[idx].ficos[id + 1]
    const nextValueIsNoScore = isNaN(nextValue) || nextValue === null
    if (isNaN(value) && !isLast) return
    if (!isNaN(value)) {
      if (isFirst && !isLast && value <= nextValue) return
      if (!isFirst && !isLast && (value >= preValue || (value <= nextValue && !nextValueIsNoScore))) return
      if (!isFirst && isLast && value >= preValue) return
    }
    if (data.maxLTVs[index].data[idx].ficos[id] === value) return
    const newData = cloneDeep(data)
    newData.maxLTVs[index].data[idx].ficos[id] = value
    setData(newData)
    setEdit(true)
  }

  const onChangeLTV = (
    index: number,
    idx: number,
    id: number,
    loanPurpose: 'purchaseRT' | 'nocashout' | 'cashout',
    value: any,
  ) => {
    if (!data) return
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    const newData = cloneDeep(data)
    if (data.maxLTVs[index].data[idx][loanPurpose][id] === value) return
    newData.maxLTVs[index].data[idx][loanPurpose][id] = value
    setData(newData)
    setEdit(true)
  }

  const onChangeLoanAmount = (index: number, idx: number, value: any, startValue = false) => {
    if (!data) return
    const newData = cloneDeep(data)

    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (startValue) {
      newData.maxLTVs[index].data[0].loanAmount.from = isNaN(value) ? 0 : value

      if (newData.maxLTVs[index].data[0].loanAmount.from > newData.maxLTVs[index].data[0].loanAmount.to)
        newData.maxLTVs[index].data[0].loanAmount.to = newData.maxLTVs[index].data[0].loanAmount.from
    } else {
      if (isNaN(value)) {
        if (idx === newData.maxLTVs[index].data.length - 1) newData.maxLTVs[index].data[idx].loanAmount.to = value
        else return
      } else if (newData.maxLTVs[index].data[idx].loanAmount.from > value) return
      else newData.maxLTVs[index].data[idx].loanAmount.to = value
    }

    if (index < newData.maxLTVs[index].data.length - 1)
      for (let i = idx + 1; i < newData.maxLTVs[index].data.length; i++) {
        if (newData.maxLTVs[index].data[i].loanAmount.from - newData.maxLTVs[index].data[i - 1].loanAmount.to !== 1)
          newData.maxLTVs[index].data[i].loanAmount.from = solveDecimalJavascriptSum([
            newData.maxLTVs[index].data[i - 1].loanAmount.to,
            1,
          ])

        if (
          newData.maxLTVs[index].data[i].loanAmount.from > newData.maxLTVs[index].data[i].loanAmount.to &&
          !isNaN(newData.maxLTVs[index].data[i].loanAmount.to) &&
          !isEmpty(newData.maxLTVs[index].data[i].loanAmount.to)
        )
          newData.maxLTVs[index].data[i].loanAmount.to = newData.maxLTVs[index].data[i].loanAmount.from
      }

    setData(newData)
    setEdit(true)
  }

  const onChangeDSCR = (index: number, value: any, startValue = false) => {
    if (!data) return
    const newData = cloneDeep(data)

    if (order === OrderOptions.desc) {
      newData.maxLTVs.reverse()
      index = newData.maxLTVs.length - index - 1
    }

    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (startValue) {
      ;(newData.maxLTVs[0].DSCR as IRange).from = isNaN(value) ? 0 : value

      if ((newData.maxLTVs[0].DSCR as IRange).from > (newData.maxLTVs[0].DSCR as IRange).to)
        (newData.maxLTVs[0].DSCR as IRange).to = (newData.maxLTVs[0].DSCR as IRange).from
    } else {
      if (isNaN(value)) {
        if (index === newData.maxLTVs.length - 1) (newData.maxLTVs[index].DSCR as IRange).to = value
        else return
      } else if ((newData.maxLTVs[index].DSCR as IRange).from > value) return
      else (newData.maxLTVs[index].DSCR as IRange).to = value
    }

    if (index < newData.maxLTVs.length - 1)
      for (let i = index + 1; i < newData.maxLTVs.length; i++) {
        if ((newData.maxLTVs[i].DSCR as IRange).from - (newData.maxLTVs[i - 1].DSCR as IRange).to !== 0.01)
          (newData.maxLTVs[i].DSCR as IRange).from = solveDecimalJavascriptSum([
            (newData.maxLTVs[i - 1].DSCR as IRange).to,
            0.01,
          ])

        if (
          (newData.maxLTVs[i].DSCR as IRange).from > (newData.maxLTVs[i].DSCR as IRange).to &&
          !isNaN((newData.maxLTVs[i].DSCR as IRange).to) &&
          !isEmpty((newData.maxLTVs[i].DSCR as IRange).to)
        )
          (newData.maxLTVs[i].DSCR as IRange).to = (newData.maxLTVs[i].DSCR as IRange).from
      }

    if (order === OrderOptions.desc) newData.maxLTVs.reverse()

    setData(newData)
    setEdit(true)
  }

  const onAddDSCR = () => {
    if (!data) return
    const newData = cloneDeep(data)

    if (order === OrderOptions.desc) newData.maxLTVs.reverse()

    if (!newData.maxLTVs.length)
      newData.maxLTVs.push({
        title: '',
        DSCR: { from: 0, to: NaN },
        data: [
          {
            loanAmount: { from: 0, to: NaN },
            ficos: [NaN],
            monthsReserve: 0,
            purchaseRT: [NaN],
            nocashout: [NaN],
            cashout: [NaN],
          },
        ],
      })
    else if (
      isNaN((newData.maxLTVs[newData.maxLTVs.length - 1].DSCR as IRange).to) ||
      isEmpty((newData.maxLTVs[newData.maxLTVs.length - 1].DSCR as IRange).to)
    ) {
      ;(newData.maxLTVs[newData.maxLTVs.length - 1].DSCR as IRange).to = (
        newData.maxLTVs[newData.maxLTVs.length - 1].DSCR as IRange
      ).from

      newData.maxLTVs.push({
        title: '',
        DSCR: {
          from: solveDecimalJavascriptSum([(newData.maxLTVs[newData.maxLTVs.length - 1].DSCR as IRange).from, 0.01]),
          to: solveDecimalJavascriptSum([(newData.maxLTVs[newData.maxLTVs.length - 1].DSCR as IRange).from, 0.01]),
        },
        data: [
          {
            loanAmount: { from: 0, to: NaN },
            ficos: [NaN],
            monthsReserve: 0,
            purchaseRT: [NaN],
            nocashout: [NaN],
            cashout: [NaN],
          },
        ],
      })
    } else
      newData.maxLTVs.push({
        title: '',
        DSCR: {
          from: solveDecimalJavascriptSum([(newData.maxLTVs[newData.maxLTVs.length - 1].DSCR as IRange).to, 0.01]),
          to: solveDecimalJavascriptSum([(newData.maxLTVs[newData.maxLTVs.length - 1].DSCR as IRange).to, 0.01]),
        },
        data: [
          {
            loanAmount: { from: 0, to: NaN },
            ficos: [NaN],
            monthsReserve: 0,
            purchaseRT: [NaN],
            nocashout: [NaN],
            cashout: [NaN],
          },
        ],
      })

    if (order === OrderOptions.desc) newData.maxLTVs.reverse()

    setData(newData)
    setEdit(true)
  }

  const onAddLoanAmount = (index: number = 0) => {
    if (!data) return
    const newData = cloneDeep(data)

    if (!newData.maxLTVs.length) {
      newData.maxLTVs.push({
        title: '',
        data: [
          {
            loanAmount: { from: 0, to: NaN },
            ficos: [NaN],
            monthsReserve: 0,
            purchaseRT: [NaN],
            nocashout: [NaN],
            cashout: [NaN],
          },
        ],
      })
    } else {
      if (!newData.maxLTVs[index].data.length)
        newData.maxLTVs[index].data.push({
          loanAmount: { from: 0, to: NaN },
          ficos: [NaN],
          monthsReserve: 0,
          purchaseRT: [NaN],
          nocashout: [NaN],
          cashout: [NaN],
        })
      else if (
        isNaN(newData.maxLTVs[index].data[newData.maxLTVs[index].data.length - 1].loanAmount.to) ||
        isEmpty(newData.maxLTVs[index].data[newData.maxLTVs[index].data.length - 1].loanAmount.to)
      ) {
        newData.maxLTVs[index].data[newData.maxLTVs[index].data.length - 1].loanAmount.to =
          newData.maxLTVs[index].data[newData.maxLTVs[index].data.length - 1].loanAmount.from

        newData.maxLTVs[index].data.push({
          loanAmount: {
            from: solveDecimalJavascriptSum([
              newData.maxLTVs[index].data[newData.maxLTVs[index].data.length - 1].loanAmount.from,
              1,
            ]),
            to: solveDecimalJavascriptSum([
              newData.maxLTVs[index].data[newData.maxLTVs[index].data.length - 1].loanAmount.from,
              1,
            ]),
          },
          ficos: [NaN],
          monthsReserve: 0,
          purchaseRT: [NaN],
          nocashout: [NaN],
          cashout: [NaN],
        })
      } else
        newData.maxLTVs[index].data.push({
          loanAmount: {
            from: solveDecimalJavascriptSum([
              newData.maxLTVs[index].data[newData.maxLTVs[index].data.length - 1].loanAmount.to,
              1,
            ]),
            to: solveDecimalJavascriptSum([
              newData.maxLTVs[index].data[newData.maxLTVs[index].data.length - 1].loanAmount.to,
              1,
            ]),
          },
          ficos: [NaN],
          monthsReserve: 0,
          purchaseRT: [NaN],
          nocashout: [NaN],
          cashout: [NaN],
        })
    }

    setData(newData)
    setEdit(true)
  }

  const onAddFICO = (values: Record<string, any>) => {
    if (!data) return
    const newData = cloneDeep(data)
    let { isNoScore, FICO } = values
    if (isNoScore) FICO = NaN
    else FICO = removeComma(FICO)
    const { index, idx } = targetIndex
    const lastValue = newData.maxLTVs[index].data[idx].ficos[newData.maxLTVs[index].data[idx].ficos.length - 1]
    const matchedIndex = isNoScore
      ? newData.maxLTVs[index].data[idx].ficos.findIndex((item) => isNaN(item) || isEmpty(item))
      : newData.maxLTVs[index].data[idx].ficos.findIndex((item) => item == FICO)

    if (matchedIndex !== -1) {
      toast(`This value already exists`, { type: 'error' })
      return
    }
    let insertIndex = newData.maxLTVs[index].data[idx].ficos.findIndex((item) => item <= FICO)
    if (insertIndex !== -1) {
      newData.maxLTVs[index].data[idx].ficos.splice(insertIndex, 0, FICO)
      newData.maxLTVs[index].data[idx].purchaseRT.splice(insertIndex, 0, NaN)
      newData.maxLTVs[index].data[idx].nocashout.splice(insertIndex, 0, NaN)
      newData.maxLTVs[index].data[idx].cashout.splice(insertIndex, 0, NaN)
    } else {
      if (isNaN(lastValue) || isEmpty(lastValue)) {
        insertIndex = newData.maxLTVs[index].data[idx].ficos.length - 1
        newData.maxLTVs[index].data[idx].ficos.splice(insertIndex, 0, FICO)
        newData.maxLTVs[index].data[idx].purchaseRT.splice(insertIndex, 0, NaN)
        newData.maxLTVs[index].data[idx].nocashout.splice(insertIndex, 0, NaN)
        newData.maxLTVs[index].data[idx].cashout.splice(insertIndex, 0, NaN)
      } else {
        newData.maxLTVs[index].data[idx].ficos.push(FICO)
        newData.maxLTVs[index].data[idx].purchaseRT.push(NaN)
        newData.maxLTVs[index].data[idx].nocashout.push(NaN)
        newData.maxLTVs[index].data[idx].cashout.push(NaN)
      }
    }
    setData(newData)
    setEdit(true)
    setAddFICO(false)
  }

  const onRemoveFICO = async (index: number, idx: number, id: number) => {
    if (!data) return
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this FICO?
        <br />
        <span className="text-gray-600 text-base">
          FICO: {convertNullToNoScore(data.maxLTVs[index].data[idx].ficos[id])}
        </span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const newData = cloneDeep(data)

    if (order === OrderOptions.desc) {
      newData.maxLTVs.reverse()
      index = newData.maxLTVs.length - index - 1
    }

    newData.maxLTVs[index].data[idx].ficos.splice(id, 1)
    newData.maxLTVs[index].data[idx].purchaseRT.splice(id, 1)
    newData.maxLTVs[index].data[idx].nocashout.splice(id, 1)
    newData.maxLTVs[index].data[idx].cashout.splice(id, 1)
    if (!newData.maxLTVs[index].data[idx].ficos.length) {
      newData.maxLTVs[index].data.splice(idx, 1)
      for (let i = 1; i < newData.maxLTVs[index].data.length; i++) {
        if (newData.maxLTVs[index].data[i].loanAmount.from - newData.maxLTVs[index].data[i - 1].loanAmount.to !== 1)
          newData.maxLTVs[index].data[i].loanAmount.from = solveDecimalJavascriptSum([
            newData.maxLTVs[index].data[i - 1].loanAmount.to,
            1,
          ])

        if (
          newData.maxLTVs[index].data[i].loanAmount.from > newData.maxLTVs[index].data[i].loanAmount.to &&
          !isNaN(newData.maxLTVs[index].data[i].loanAmount.to) &&
          !isEmpty(newData.maxLTVs[index].data[i].loanAmount.to)
        )
          newData.maxLTVs[index].data[i].loanAmount.to = newData.maxLTVs[index].data[i].loanAmount.from
      }

      if (!newData.maxLTVs[index].data.length) {
        newData.maxLTVs.splice(index, 1)
        for (let i = 1; i < newData.maxLTVs.length; i++) {
          if ((newData.maxLTVs[i].DSCR as IRange).from - (newData.maxLTVs[i - 1].DSCR as IRange).to !== 0.01)
            (newData.maxLTVs[i].DSCR as IRange).from = solveDecimalJavascriptSum([
              (newData.maxLTVs[i - 1].DSCR as IRange).to,
              0.01,
            ])

          if (
            (newData.maxLTVs[i].DSCR as IRange).from > (newData.maxLTVs[i].DSCR as IRange).to &&
            !isNaN((newData.maxLTVs[i].DSCR as IRange).to) &&
            !isEmpty((newData.maxLTVs[i].DSCR as IRange).to)
          )
            (newData.maxLTVs[i].DSCR as IRange).to = (newData.maxLTVs[i].DSCR as IRange).from
        }
      }
    }

    if (order === OrderOptions.desc) newData.maxLTVs.reverse()

    setData(newData)
    setEdit(true)
  }

  const renderTable = useMemo(() => {
    if (!data) return <></>
    return (
      <div className="px-2">
        {data.maxLTVs.map((item, index) => {
          let dscrRowSpan = 0
          item.data.forEach((v) => (dscrRowSpan += v.ficos.length + 1))
          return (
            <table key={index} className="table w-full text-center mb-4">
              <thead className="bg-gray-50">
                <tr className="text-sm">
                  {isDSCR && (
                    <th colSpan={2} className="border p-1">
                      DSCR
                    </th>
                  )}
                  <th colSpan={2} className="border p-1">
                    <div className="flex justify-center items-center w-full gap-2">
                      <span>Loan Amount</span>

                      {isDSCR && (
                        <div
                          className="flex justify-center items-center gap-1 cursor-pointer text-shade-blue"
                          onClick={() => onAddLoanAmount(index)}
                        >
                          <PlusIcon className="w-4 h-4" />
                          <span>Add</span>
                        </div>
                      )}
                    </div>
                  </th>
                  <th rowSpan={2} className="border p-1 w-[130px]">
                    Min Months Reserve
                  </th>
                  <th rowSpan={2} className="border p-1 w-[120px]">
                    FICO
                  </th>
                  <th rowSpan={2} className="border p-1 w-[140px]">
                    Purchase
                  </th>
                  <th rowSpan={2} className="border p-1 w-[140px]">
                    No-Cashout
                  </th>
                  <th rowSpan={2} className="border p-1 w-[140px]">
                    Cashout
                  </th>
                  <th rowSpan={2} className="border p-1">
                    Action
                  </th>
                </tr>
                <tr className="text-sm">
                  {isDSCR && (
                    <>
                      <th className="border p-1 w-[90px]">From</th>
                      <th className="border p-1 w-[90px]">To</th>
                    </>
                  )}
                  <th className="border p-1 w-[150px]">From</th>
                  <th className="border p-1 w-[150px]">To</th>
                </tr>
              </thead>

              <tbody className="text-sm">
                {item.data.map((limit, idx) => {
                  return (
                    <Fragment key={`${index}-${idx}`}>
                      <tr>
                        {isDSCR && item?.DSCR && idx === 0 && (
                          <>
                            <td rowSpan={dscrRowSpan} className="border p-1">
                              <div className="flex justify-center">
                                <PlainInput
                                  value={item.DSCR.from}
                                  origin={item.DSCR.from}
                                  content={item.DSCR.from}
                                  disabled={
                                    order === OrderOptions.asc ? index !== 0 : index !== data.maxLTVs.length - 1
                                  }
                                  onChange={(value: any) => onChangeDSCR(index, value, true)}
                                  className="w-full"
                                />
                              </div>
                            </td>
                            <td rowSpan={dscrRowSpan} className="border p-1">
                              <div className="flex justify-center">
                                <PlainInput
                                  value={item.DSCR.to}
                                  origin={convertNullToBlank(item.DSCR.to)}
                                  content={convertNullToPlus(item.DSCR.to)}
                                  onChange={(value: any) => onChangeDSCR(index, value)}
                                  className="w-full"
                                />
                              </div>
                            </td>
                          </>
                        )}
                        <td rowSpan={limit.ficos.length + 1} className="border p-1">
                          <div className="flex justify-center">
                            <PlainInput
                              value={limit.loanAmount.from}
                              origin={limit.loanAmount.from}
                              content={getPrice3decimal(limit.loanAmount.from)}
                              onChange={(value: any) => onChangeLoanAmount(index, idx, value, idx === 0)}
                              disabled={idx !== 0}
                              className="w-full"
                            />
                          </div>
                        </td>
                        <td rowSpan={limit.ficos.length + 1} className="border p-1">
                          <div className="flex justify-center">
                            <PlainInput
                              value={limit.loanAmount.to}
                              origin={convertNullToBlank(limit.loanAmount.to)}
                              content={convertNullToPlus(limit.loanAmount.to)}
                              onChange={(value: any) => onChangeLoanAmount(index, idx, value)}
                              className="w-full"
                            />
                          </div>
                        </td>
                        <td rowSpan={limit.ficos.length + 1} className="border p-1">
                          <div className="flex justify-center">
                            <PlainInput
                              value={limit.monthsReserve}
                              origin={limit.monthsReserve}
                              content={limit.monthsReserve + 'Mo'}
                              onChange={(value: any) => onChangeMinMonthsReserve(index, idx, value)}
                              type="number"
                              className="w-full"
                            />
                          </div>
                        </td>
                      </tr>

                      {limit.ficos.map((fico, id) => (
                        <tr key={`${index}-${idx}-${id}`}>
                          <td className="border p-0.5">
                            <div className="flex justify-center">
                              <PlainInput
                                value={fico}
                                origin={convertNullToNoScore(fico)}
                                content={convertNullToNoScore(fico)}
                                onChange={(value: any) => onChangeFICO(index, idx, id, value)}
                                className="w-full"
                              />
                            </div>

                            {id === limit.ficos.length - 1 && (
                              <div
                                className="flex justify-center items-center gap-1 cursor-pointer text-shade-blue text-xs"
                                onClick={() => {
                                  setTargetIndex({ index, idx })
                                  setAddFICO(true)
                                }}
                              >
                                <PlusIcon className="w-3 h-3" />
                                <span>Add FICO</span>
                              </div>
                            )}
                          </td>
                          <td className="border p-0.5">
                            <div className="flex justify-center">
                              <PlainInput
                                value={limit.purchaseRT[id]}
                                origin={convertNullToBlank(limit.purchaseRT[id])}
                                content={convertNullValue(limit.purchaseRT[id])}
                                onChange={(value: any) => onChangeLTV(index, idx, id, 'purchaseRT', value)}
                                className="w-full"
                              />
                            </div>
                          </td>
                          <td className="border p-0.5">
                            <div className="flex justify-center">
                              <PlainInput
                                value={limit.nocashout[id]}
                                origin={convertNullToBlank(limit.nocashout[id])}
                                content={convertNullValue(limit.nocashout[id])}
                                onChange={(value: any) => onChangeLTV(index, idx, id, 'nocashout', value)}
                                className="w-full"
                              />
                            </div>
                          </td>
                          <td className="border p-0.5">
                            <div className="flex justify-center">
                              <PlainInput
                                value={limit.cashout[id]}
                                origin={convertNullToBlank(limit.cashout[id])}
                                content={convertNullValue(limit.cashout[id])}
                                onChange={(value: any) => onChangeLTV(index, idx, id, 'cashout', value)}
                                className="w-full"
                              />
                            </div>
                          </td>
                          <td className="border p-0.5">
                            <div className="flex justify-center">
                              <div
                                className="w-fit p-0.5 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                                onClick={() => onRemoveFICO(index, idx, id)}
                              >
                                <TrashIcon className="w-3.5 h-3.5 hover:cursor-pointer text-red-700" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  )
                })}
              </tbody>
            </table>
          )
        })}
      </div>
    )
  }, [data, isDSCR, order])

  const renderMenus = useMemo(() => {
    return <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={onSelectMenu} />
  }, [menus, selectedMenu])

  return (
    <div className="relative">
      <LayoutLoading show={loading === LoadingStatus.SAVE_RESTRICTION} />
      <div className="text-lg font-variation-settings-600 mb-3">Max LTV Limits</div>
      {renderMenus}

      <div className={`flex items-center my-2 px-2 ${edit ? 'justify-between' : 'justify-end'}`}>
        {edit && (
          <div className="flex justify-end">
            <Button
              className="pt-1.5 pb-1.5"
              onClick={() => {
                if (!data) return
                onSave(data, restrictionKey)
                setEdit(false)
              }}
            >
              Save
            </Button>
          </div>
        )}

        <div className="flex items-center gap-4">
          {isDSCR && (
            <Select
              id="order"
              title="Change Order"
              options={Object.values(OrderOptions)}
              value={order}
              className="-mb-2 w-48"
              onChange={(value) => {
                if (!data) return
                const newData = cloneDeep(data)
                newData.maxLTVs.reverse()
                setData(newData)
                setOrder(value)
                setEdit(true)
              }}
            />
          )}

          <div
            className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
            onClick={() => {
              if (isDSCR) onAddDSCR()
              else onAddLoanAmount()
            }}
          >
            <PlusIcon className="w-4 h-4" />
            <span>{isDSCR ? 'Add DSCR' : 'Add Loan Amount'}</span>
          </div>
        </div>
      </div>

      {renderTable}

      {addFICO && <AddNewFICOModal isOpen={addFICO} onClose={() => setAddFICO(false)} onSubmit={onAddFICO} />}
    </div>
  )
}
