import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import type { ICommercialOnlyProgrammed, IRange, IRatePriceAdjustment, IRatePriceAdjustmentValue } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Modal } from 'stories/components'
import { confirm, getPrice3decimal, isEmpty, solveDecimalJavascriptSum } from 'utils'

import { LtvRatePriceRangeNames } from '..'
import { convertNullToPlus, convertNullValue } from '../logic'

interface IProps {
  programData: ICommercialOnlyProgrammed
  loading: string
  name: string
  step?: number
  onClose: () => void
  onSubmit: (name: string, ficos: IRange[], ratePriceAdj: IRatePriceAdjustment) => void
}

export const RatePriceRangeEditModal = (props: IProps) => {
  const { name, loading, programData, step = 1, onClose, onSubmit } = props

  const [isUpdated, setIsUpdated] = useState(false)
  const [range, setRange] = useState<IRange[]>([])
  const [noScoreRange, setNoScoreRange] = useState<IRange>()
  const [noScorePurchase, setNoScorePurchase] = useState<IRatePriceAdjustmentValue>()
  const [noScoreNoCashout, setNoScoreNoCashout] = useState<IRatePriceAdjustmentValue>()
  const [noScoreCashout, setNoScoreCashout] = useState<IRatePriceAdjustmentValue>()
  const [ratePriceAdj, setRatePriceAdj] = useState<IRatePriceAdjustment>({
    purchase: [],
    nocashout: [],
    cashout: [],
  })

  useEffect(() => {
    if (name === LtvRatePriceRangeNames.fico) {
      const newData = cloneDeep(programData)
      const { ficos, ficoAdjustment } = newData
      setNoScoreRange(ficos.pop())
      setNoScorePurchase(ficoAdjustment.purchase.pop())
      setNoScoreNoCashout(ficoAdjustment.nocashout.pop())
      setNoScoreCashout(ficoAdjustment.cashout.pop())
      setRange(ficos)
      setRatePriceAdj(ficoAdjustment)
    } else if (name === LtvRatePriceRangeNames.reserved) {
      setRange(programData.monthsReserve)
      setRatePriceAdj(programData.monthsReserveAdjustment)
    } else if (name === LtvRatePriceRangeNames.aivLTV) {
      setRange(programData.aivLtvs)
      setRatePriceAdj(programData.aivLtvAdjustment)
    } else if (name === LtvRatePriceRangeNames.LTC) {
      setRange(programData.ltcs)
      setRatePriceAdj(programData.ltcAdjustment)
    } else {
      setRange([])
      setRatePriceAdj({
        purchase: [],
        nocashout: [],
        cashout: [],
      })
    }
  }, [programData, name])

  const onAddNewRange = () => {
    const newRanges = cloneDeep(range)
    const newRatePriceAdj = cloneDeep(ratePriceAdj)

    if (!newRanges.length)
      newRanges.push({
        from: 0,
        to: 0,
      })
    else if (isNaN(newRanges[newRanges.length - 1].to) || isEmpty(newRanges[newRanges.length - 1].to)) {
      newRanges[newRanges.length - 1].to = newRanges[newRanges.length - 1].from

      newRanges.push({
        from: solveDecimalJavascriptSum([newRanges[newRanges.length - 1].from, step]),
        to: solveDecimalJavascriptSum([newRanges[newRanges.length - 1].from, step]),
      })
    } else
      newRanges.push({
        from: solveDecimalJavascriptSum([newRanges[newRanges.length - 1].to, step]),
        to: solveDecimalJavascriptSum([newRanges[newRanges.length - 1].to, step]),
      })

    newRatePriceAdj.purchase.push({ rate: 0, price: 0 })
    newRatePriceAdj.nocashout.push({ rate: 0, price: 0 })
    newRatePriceAdj.cashout.push({ rate: 0, price: 0 })

    setRange(newRanges)
    setRatePriceAdj(newRatePriceAdj)
  }

  const onRemove = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          {name}: {getPrice3decimal(range[index].from)}{' '}
          {isEmpty(range[index].to) || isNaN(range[index].to) ? '+' : `- ${getPrice3decimal(range[index].to)}`}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newRanges = cloneDeep(range)
    const newRatePriceAdj = cloneDeep(ratePriceAdj)

    newRanges.splice(index, 1)
    for (let i = 1; i < newRanges.length; i++) {
      if (newRanges[i].from - newRanges[i - 1].to !== step)
        newRanges[i].from = solveDecimalJavascriptSum([newRanges[i - 1].to, step])

      if (newRanges[i].from > newRanges[i].to && !isNaN(newRanges[i].to) && !isEmpty(newRanges[i].to))
        newRanges[i].to = newRanges[i].from
    }

    newRatePriceAdj.purchase.splice(index, 1)
    newRatePriceAdj.nocashout.splice(index, 1)
    newRatePriceAdj.cashout.splice(index, 1)

    setRange(newRanges)
    setRatePriceAdj(newRatePriceAdj)
  }

  const onChangeRange = (index: number, value: any, startValue = false) => {
    const newRanges = cloneDeep(range)

    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (startValue) {
      newRanges[0].from = isNaN(value) ? 0 : value

      if (newRanges[0].from > newRanges[0].to) newRanges[0].to = newRanges[0].from
    } else {
      if (isNaN(value)) {
        if (index === newRanges.length - 1) newRanges[index].to = value
        else {
          setIsUpdated(!isUpdated)
          return
        }
      } else if (newRanges[index].from > value) {
        setIsUpdated(!isUpdated)
        return
      } else newRanges[index].to = value
    }

    if (index < newRanges.length - 1)
      for (let i = index + 1; i < newRanges.length; i++) {
        if (newRanges[i].from - newRanges[i - 1].to !== 1)
          newRanges[i].from = solveDecimalJavascriptSum([newRanges[i - 1].to, step])

        if (newRanges[i].from > newRanges[i].to && !isNaN(newRanges[i].to) && !isEmpty(newRanges[i].to))
          newRanges[i].to = newRanges[i].from
      }

    setRange(newRanges)
  }

  const onChangeRatePriceAdj = (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    const newRatePriceAdj = cloneDeep(ratePriceAdj)

    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)
    ;(newRatePriceAdj[loanPurpose][index] as any)[key] = value

    setRatePriceAdj(newRatePriceAdj)
  }

  const renderRange = useMemo(() => {
    return (
      <table className="table w-full text-center text-sm">
        <thead className="bg-gray-100">
          <tr>
            <th colSpan={2} className="p-2 border">
              {name}
            </th>
            <th rowSpan={2} className="p-2 border whitespace-nowrap">
              Loan Purpose
            </th>
            <th rowSpan={2} className="p-2 border whitespace-nowrap w-28">
              Rate Adjustment
            </th>
            <th rowSpan={2} className="p-2 border whitespace-nowrap w-28">
              Price Adjustment
            </th>
            <th rowSpan={2} className="p-2 border whitespace-nowrap">
              Action
            </th>
          </tr>
          <tr>
            <th className="border p-2 w-24">From</th>
            <th className="border p-2 w-24">To</th>
          </tr>
        </thead>

        <tbody>
          {range.map((item, index) => (
            <Fragment key={index}>
              <tr>
                <td rowSpan={3} className="border p-1">
                  <div className="flex justify-center">
                    <PlainInput
                      value={item.from}
                      origin={item.from}
                      content={item.from}
                      onChange={(value: any) => onChangeRange(index, value, index === 0)}
                      disabled={index !== 0}
                      className="w-full"
                    />
                  </div>
                </td>
                <td rowSpan={3} className="border p-1">
                  <div className="flex justify-center">
                    <PlainInput
                      value={item.to}
                      origin={item.to}
                      content={convertNullToPlus(item.to)}
                      onChange={(value: any) => onChangeRange(index, value)}
                      className="w-full"
                    />
                  </div>
                </td>
                <td className="border p-1">Purchase</td>
                {['rate', 'price'].map((key, idx) => (
                  <td key={`${index}-${idx}`} className="border p-1">
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue((ratePriceAdj.purchase[index] as any)[key])}
                        origin={convertNullValue((ratePriceAdj.purchase[index] as any)[key])}
                        content={convertNullValue((ratePriceAdj.purchase[index] as any)[key])}
                        onChange={(value: any) => onChangeRatePriceAdj(index, 'purchase', key, value)}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                <td rowSpan={3} className="p-1 border">
                  <div className="flex justify-center">
                    <div
                      className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                      onClick={() => onRemove(index)}
                    >
                      <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="border p-1">No-Cashout</td>
                {['rate', 'price'].map((key, idx) => (
                  <td key={`${index}-${idx}`} className="border p-1">
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue((ratePriceAdj.nocashout[index] as any)[key])}
                        origin={convertNullValue((ratePriceAdj.nocashout[index] as any)[key])}
                        content={convertNullValue((ratePriceAdj.nocashout[index] as any)[key])}
                        onChange={(value: any) => onChangeRatePriceAdj(index, 'nocashout', key, value)}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
              </tr>

              <tr>
                <td className="border p-1">Cashout</td>
                {['rate', 'price'].map((key, idx) => (
                  <td key={`${index}-${idx}`} className="border p-1">
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue((ratePriceAdj.cashout[index] as any)[key])}
                        origin={convertNullValue((ratePriceAdj.cashout[index] as any)[key])}
                        content={convertNullValue((ratePriceAdj.cashout[index] as any)[key])}
                        onChange={(value: any) => onChangeRatePriceAdj(index, 'cashout', key, value)}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    )
  }, [range, ratePriceAdj, isUpdated])

  return (
    <Modal
      title={`Edit ${name} Rate & Price Adjustment`}
      titleOkay="Save"
      isOpen={true}
      loading={loading === LoadingStatus.UPDATE_RANGE}
      onClose={onClose}
      onOk={() => {
        const newRange = cloneDeep(range)
        const newAdjust = cloneDeep(ratePriceAdj)
        if (name === LtvRatePriceRangeNames.fico) {
          if (noScoreRange) newRange.push(noScoreRange)
          if (noScorePurchase) newAdjust.purchase.push(noScorePurchase)
          if (noScoreNoCashout) newAdjust.nocashout.push(noScoreNoCashout)
          if (noScoreCashout) newAdjust.cashout.push(noScoreCashout)
        }
        onSubmit(name, newRange, newAdjust)
      }}
    >
      <div className="max-w-4xl">
        {renderRange}

        <div className="flex justify-start mt-2">
          <div
            className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
            onClick={onAddNewRange}
          >
            <PlusIcon className="w-4 h-4" />
            <span>Add Adjustment</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}
