import { PencilSquareIcon } from '@heroicons/react/24/outline'
import type { IProgram } from 'config'
import { UpdateOccupancyLtvReduction } from 'pages/RateSheetOverview/Modals'
import { ProgramMargin } from 'pages/RateSheetOverview/PriceMargin/ProgramMargin'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import excelService from 'services/excelService'
import Sheet, { Style } from 'sheet-happens'
import { Button } from 'stories/components'
import { getPrice3decimal, isEmpty } from 'utils'

import { LoadingStatus } from '../..'
import { commercialDscrInformations } from '../../constants'
import { UpdateRestrictionModal } from './UpdateRestrictionModal'

interface Props {
  program: IProgram
  loading: string
  onSave: (data: Record<string, any>) => {}
  onUpdateRestriction: (restriction: Record<string, any>) => Promise<boolean>
  onChangeLtvReduction: (value: number) => Promise<boolean>
  onSaveBonusMargin: (data: any, key: string) => void
  onSavePriceMarginLimit: (data: any, key: string) => void
}

interface IData {
  sheet: any[]
}

export interface ICommercialDSCRRestriction {
  RateReduction: number
  MinRate: number
}

export function CommercialDSCRSheet({
  program,
  loading,
  onSave,
  onUpdateRestriction,
  onChangeLtvReduction,
  onSaveBonusMargin,
  onSavePriceMarginLimit,
}: Props) {
  const [data, setData] = useState<any[][]>([[], []])
  const [edit, setEdit] = useState<Record<string, number>>({})
  const [cellWidth, setCellWidth] = useState<number[]>([120, 100, 100, 100, 100, 120, 120, 120, 120])
  const [cellHeight, setCellHeight] = useState<number[]>([])
  const [editable, setEditable] = useState<string[]>([])
  const [editObject, setEditObject] = useState<Record<string, any>>({})
  const [restriction, setRestriction] = useState<ICommercialDSCRRestriction>({
    RateReduction: 0.375,
    MinRate: 7.24,
  })
  const [dscrLtvRdt, setDscrLtvRdt] = useState<number>(5)
  const [modal, setModal] = useState('')

  useEffect(() => {
    ;(async function initializeData() {
      if (program.Restriction?.RateReduction || program.Restriction?.MinRate) {
        setRestriction({
          RateReduction: program.Restriction.RateReduction ?? 0,
          MinRate: program.Restriction.MinRate ?? 0,
        })
      }

      if (program.Restriction?.DscrOccupancyLtvReduction) setDscrLtvRdt(program.Restriction.DscrOccupancyLtvReduction)
      else setDscrLtvRdt(5)

      let { sheet } = (await excelService.parseCommercialDSCRProgram(program.OtherTypeProgramData)) as IData
      setData(sheet)
      setEdit({})

      let _editable = ['2-0', '2-1', '2-2', '2-3', '0-6', '0-8', '11-0', '11-1', '11-2', '11-3', '9-6', '9-8']
      const _rows = [2, 3, 4, 5, 6, 7, 11, 12, 13, 14, 15, 16]
      const _columns = [4, 5, 6, 7, 8]
      _rows.map((y) => {
        _columns.map((x) => {
          _editable.push(`${y}-${x}`)
        })
      })
      setEditable(_editable)
      setEditObject(program.OtherTypeProgramData)
    })()
  }, [program])

  const onSelectionChanged = (x1: number, y1: number, x2: number, y2: number) => {
    console.log('track change', x1, y1, x2, y2)
    return {}
  }

  const columnHeaders = ['A', 'B', 'C']

  const cellStyle = (x: number, y: number) => {
    let rlt: Record<string, any> = {
      textAlign: 'left',
    }

    if ([0, 1, 9, 10].indexOf(y) !== -1) {
      if ([0, 1, 2, 3, 4, 5, 6, 7, 8].indexOf(x) !== -1) {
        rlt.fillColor = '#6DA2FB22'
        rlt.weight = 'bold'
      }
    }

    if (editable.indexOf(`${y}-${x}`) !== -1) {
      rlt.fillColor = '#f7f9fa'
    }
    return rlt
  }

  const editData = (x: number, y: number) => {
    return data?.[y]?.[x]
  }

  const displayData = (x: number, y: number) => {
    let rlt = data?.[y]?.[x]
    if ([0, 9].indexOf(y) !== -1) {
      if ([6, 8].indexOf(x) !== -1) rlt = !isEmpty(rlt) ? 'Price at ' + rlt : 'Price at N/A'
    }
    if (['2-0', '2-1', '11-0', '11-1'].indexOf(`${y}-${x}`) !== -1) {
      rlt = '$' + getPrice3decimal(rlt)
    }
    if (x >= 5 && x <= 8) {
      if ((y >= 2 && y <= 7) || (y >= 11 && y <= 16)) {
        if (rlt === '') rlt = 'N/A'
        else if (rlt !== 'N/A') rlt += '%'
      }
    }
    return rlt
  }

  const sourceData = (x: number, y: number) => {
    return data?.[y]?.[x]
  }

  const isReadOnly = (x: number, y: number) => {
    if (editable.indexOf(`${y}-${x}`) === -1) {
      return true
    }
    return false
  }

  const onCellWidthChange = (columnIdx: any, newWidth: number) => {
    console.log(columnIdx, newWidth)
    const cw = [...cellWidth]
    if (columnIdx > cw.length) {
      for (let i = cw.length; i <= columnIdx; i++) {
        cw.push(100)
      }
    }
    cw[columnIdx] = newWidth
    setCellWidth(cw)
  }

  const onCellHeightChange = (rowIdx: any, newHeight: number) => {
    const ch = [...cellHeight]
    if (rowIdx > ch.length) {
      for (let i = ch.length; i <= rowIdx; i++) {
        ch.push(22)
      }
    }
    ch[rowIdx] = newHeight
    setCellHeight(ch)
  }

  const onChange = async (changes: any[]) => {
    let newData = [...data]
    let newEdit: Record<string, number> = { ...edit }

    for (const change of changes) {
      let { x, y, value } = change

      if (newData[y][x] === value) continue

      if (value === '') value = ''
      else value = isNaN(Number(value)) ? '' : Number(Number(value).toFixed(3))

      if (editable.indexOf(`${y}-${x}`) !== -1) {
        let yy = y
        let key = data[0][0]
        if (y >= 9) {
          yy = y - 9
          key = data[9][0]
        }
        if (yy === 0) {
          if (x === 6) {
            editObject[key].purchasePrice = value
          } else if (x === 8) {
            editObject[key].refinancePrice = value
          }
        } else if (x <= 3) {
          if (x === 0) editObject[key].loanAmount.from = value
          if (x === 1) editObject[key].loanAmount.to = value
          if (x === 2) editObject[key].monthsReserve = value
          if (x === 3) editObject[key].minDscr = value
        } else {
          const index = yy - 2
          if (x === 4) editObject[key].ficos[index] = value
          if (x === 5) editObject[key].purchaseLTVs[index] = value
          if (x === 6) editObject[key].purchaseRates[index] = value
          if (x === 7) editObject[key].refinanceLTVs[index] = value
          if (x === 8) editObject[key].refinanceRates[index] = value
        }
        newData[y][x] = value
        newEdit[`${y}-${x}`] = value
      }
    }
    setEditObject(editObject)
    setEdit(newEdit)
    setData(newData)
  }

  const onUpdate = async (newRestriction: ICommercialDSCRRestriction) => {
    if (!newRestriction.RateReduction) {
      toast("'Rate Reduction' is Required!", { type: 'error' })
      return
    }
    if (!newRestriction.MinRate) {
      toast("'Min Rate' is Required!", { type: 'error' })
      return
    }

    const res = await onUpdateRestriction(newRestriction)

    if (res) {
      setRestriction(newRestriction)
      setModal('')
    }
  }

  const onSaveDscrLtvRdt = async (value: number) => {
    setDscrLtvRdt(value)
    const res = await onChangeLtvReduction(value)

    if (res) setModal('')
  }

  return (
    <div className="py-6">
      <div className="relative max-w-screen-2xl m-auto w-full">
        <div className="flex flex-col justify-center items-center mb-5">
          <div className="w-full lg:w-2/3">
            <div className="shadow1 rounded py-3 px-4 mb-6">
              <ProgramMargin
                program={program}
                loading={loading}
                onSaveBonusMargin={onSaveBonusMargin}
                onSavePriceMarginLimit={onSavePriceMarginLimit}
              />
            </div>

            <p className="font-variation-settings-600 mb-2">{program.Type} General Information</p>

            <table className="w-full">
              <tbody>
                {Object.keys(commercialDscrInformations).map((key: string, index: number) => {
                  const logic = commercialDscrInformations[key]
                  const rlt = []
                  rlt.push(
                    <tr key={index}>
                      <th className="border bg-gray-100 py-2">{key}</th>
                    </tr>,
                  )

                  rlt.push(
                    <tr key={`${key}-${index}`}>
                      <td className="border py-2 px-3 text-sm">
                        <ul className="list-disc pl-5">
                          {key === 'General' && (
                            <li className="my-2">
                              <div className="flex items-center">
                                <span>
                                  1-point fee = {restriction.RateReduction}% rate reduction. | Minimum Rate{' '}
                                  {restriction.MinRate}%.
                                </span>

                                <span
                                  className="hover-shadow1 p-1 rounded hover:cursor-pointer ml-2"
                                  onClick={() => setModal('updateRestriction')}
                                >
                                  <PencilSquareIcon className="w-4 h-4 text-shade-blue" />
                                </span>
                              </div>
                            </li>
                          )}

                          {key === `Credit Event & Housing Lates` && (
                            <li className="my-2 text-sm">
                              <div className="flex items-center">
                                <span>Occupancy Type = 'Vacant' then {dscrLtvRdt}% LTV Reduction</span>
                                <span
                                  className="hover-shadow1 p-1 rounded hover:cursor-pointer ml-2"
                                  onClick={() => setModal('updateLtvReduction')}
                                >
                                  <PencilSquareIcon className="w-4 h-4 text-shade-blue" />
                                </span>
                              </div>
                            </li>
                          )}

                          {logic.map((item: string, id: number) => (
                            <li key={`${index}-${id}`} className="my-2">
                              {item}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>,
                  )

                  return rlt
                })}
              </tbody>
            </table>
          </div>
        </div>

        {program && Object.keys(edit).length > 0 && (
          <div className="flex justify-end">
            <Button
              loading={loading === LoadingStatus.SAVE_PROGRAM_CHANGES}
              onClick={async () => {
                await onSave(editObject)
                setEdit({})
              }}
            >
              Save
            </Button>
          </div>
        )}
        <Sheet
          onSelectionChanged={onSelectionChanged}
          onRightClick={() => {}}
          columnHeaders={columnHeaders}
          cellStyle={cellStyle as Style}
          editData={editData}
          displayData={displayData}
          sourceData={sourceData}
          cellWidth={cellWidth}
          cellHeight={cellHeight}
          onChange={onChange}
          readOnly={isReadOnly}
          onCellWidthChange={onCellWidthChange}
          onCellHeightChange={onCellHeightChange}
        />
      </div>

      {modal === 'updateRestriction' && (
        <UpdateRestrictionModal
          isOpen={modal === 'updateRestriction'}
          onClose={() => setModal('')}
          loading={loading}
          restriction={restriction}
          onSubmit={onUpdate}
        />
      )}

      {modal === 'updateLtvReduction' && (
        <UpdateOccupancyLtvReduction
          value={dscrLtvRdt}
          isOpen={modal === 'updateLtvReduction'}
          loading={loading}
          onClose={() => setModal('')}
          onSubmit={onSaveDscrLtvRdt}
        />
      )}
    </div>
  )
}
