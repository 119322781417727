import type {
  ICityCountyTier,
  IExperienceCityCountyTier,
  IExperienceMsaTier,
  IMsaTier,
  IPropertyTypeLtvAdj,
  IStateCitiesCounties,
  IStateMSA,
} from 'config'
import { getPrice3decimal, isEmpty } from 'utils'

import { NoUnitPropertyType } from './MsaTiers/MsaTiers'

export const getTitleAndIndex = (x: number, y: number, rows: Record<string, number[]>) => {
  const ltvCols = [1, 2, 3, 4]
  const rateCols = [1, 2, 3, 4, 5, 6]
  const stackCols = [8, 9, 10, 11]
  const loanAmountCols = [1, 2]

  const ltvLimitRows = [2, 3, 4]

  const maxRatePriceAdjRow: number[] = rows.maxRatePriceAdjRow
  const experienceRows: number[] = rows.experienceRows
  const loanTermAdjRows: number[] = rows.loanTermAdjRows
  const aivLtvAdjRows: number[] = rows.aivLtvAdjRows
  const ltcAdjRows: number[] = rows.ltcAdjRows
  const ficoAdjRows: number[] = rows.ficoAdjRows
  const monthsReserveAdjRows: number[] = rows.monthsReserveAdjRows
  const propertyTypeRateAdjRows: number[] = rows.propertyTypeRateAdjRows
  const citizenshipRateAdjRows: number[] = rows.citizenshipRateAdjRows
  const bankruptcyRateAdjRows: number[] = rows.bankruptcyRateAdjRows
  const foreclosureRateAdjRows: number[] = rows.foreclosureRateAdjRows
  const deedInLieuRateAdjRows: number[] = rows.deedInLieuRateAdjRows
  const shortsaleRateAdjRows: number[] = rows.shortsaleRateAdjRows
  const mortgageLatesRateAdjRows: number[] = rows.mortgageLatesRateAdjRows
  const forbearanceRateAdjRows: number[] = rows.forbearanceRateAdjRows
  const rehabBudgetTypesRateAdjRows: number[] = rows.rehabBudgetTypesRateAdjRows

  let title = ''
  let index = 0

  if (ltvLimitRows.includes(y) && ltvCols.includes(x)) {
    title = 'ltvLimits'
    index = y - ltvLimitRows[0]
  } else if (maxRatePriceAdjRow.includes(y) && rateCols.includes(x)) {
    title = 'maxRatePriceAdjustment'
  } else if (experienceRows.includes(y) && rateCols.includes(x)) {
    title = 'experienceAdjustment'
    index = y - experienceRows[0]
  } else if (loanTermAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'loanTermAdjustment'
    index = y - loanTermAdjRows[0]
  } else if (aivLtvAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'aivLtvAdjustment'
    index = y - aivLtvAdjRows[0]
  } else if (ltcAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'ltcAdjustment'
    index = y - ltcAdjRows[0]
  } else if (ficoAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'ficoAdjustment'
    index = y - ficoAdjRows[0]
  } else if (monthsReserveAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'monthsReserveAdjustment'
    index = y - monthsReserveAdjRows[0]
  } else if (propertyTypeRateAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'propertyTypeAdjustment'
    index = y - propertyTypeRateAdjRows[0]
  } else if (citizenshipRateAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'citizenshipAdjustment'
    index = y - citizenshipRateAdjRows[0]
  } else if (bankruptcyRateAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'bankruptcyAdjustment'
    index = y - bankruptcyRateAdjRows[0]
  } else if (foreclosureRateAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'foreclosureAdjustment'
    index = y - foreclosureRateAdjRows[0]
  } else if (deedInLieuRateAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'deedInLieuAdjustment'
    index = y - deedInLieuRateAdjRows[0]
  } else if (shortsaleRateAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'shortsaleAdjustment'
    index = y - shortsaleRateAdjRows[0]
  } else if (mortgageLatesRateAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'mortgageLatesAdjustment'
    index = y - mortgageLatesRateAdjRows[0]
  } else if (forbearanceRateAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'forbearanceAdjustment'
    index = y - forbearanceRateAdjRows[0]
  } else if (rehabBudgetTypesRateAdjRows.includes(y) && rateCols.includes(x)) {
    title = 'rehabBudgetTypesAdjustment'
    index = y - rehabBudgetTypesRateAdjRows[0]
  } else if (stackCols.includes(x) && y !== 0) {
    index = y - 1

    switch (x) {
      case 8:
        title = 'baseRates'
        break
      case 9:
        title = 'basePrices'
        break
      case 10:
        title = 'rateSpreads'
        break
      case 11:
        title = 'exitFees'
        break
    }
  } else if (loanAmountCols.includes(x) && y === 0) {
    title = 'loanAmount'
  }

  return {
    title: title,
    index: index,
  }
}

export const checkState = (data: IStateCitiesCounties, state: string) => {
  if (Object.keys(data).includes(state) && data[state].length > 0) return true
  else return false
}

export const checkAndGetValue = (
  data: ICityCountyTier[] | IExperienceCityCountyTier[],
  values: string[],
  value: string,
  state: string,
  tierName: string,
) => {
  let existTierName

  data.forEach((item) => {
    if (item.values[state] && item.values[state].includes(value)) existTierName = item.title
  })

  if (existTierName === tierName || !existTierName) {
    if (values.includes(value)) return true
    return false
  } else return true
}

export const checkDisableValue = (
  data: ICityCountyTier[] | IExperienceCityCountyTier[],
  value: string,
  state: string,
  tierName: string,
) => {
  let existTierName

  data.forEach((item) => {
    if (item.values[state] && item.values[state].includes(value)) existTierName = item.title
  })

  return existTierName && existTierName !== tierName ? true : false
}

export const getAllAvailableItems = (
  data: ICityCountyTier[] | IExperienceCityCountyTier[],
  stateData: string[],
  state: string,
  tierName: string,
) => {
  const alreadyUsedItems: string[] = []

  data.forEach((item) => {
    if (item.title !== tierName) {
      if (item.values[state]) alreadyUsedItems.push(...item.values[state])
    }
  })

  const availableItems = stateData.filter((item) => !alreadyUsedItems.includes(item))

  return availableItems
}

export const parseValues = (
  data: ICityCountyTier[] | IExperienceCityCountyTier[],
  values: string[],
  state: string,
  tierName: string,
  stateData: string[],
) => {
  const alreadyUsedItems: string[] = []
  const selectedItems: string[] = []

  data.forEach((item) => {
    if (item.title !== tierName) {
      if (item.values[state]) alreadyUsedItems.push(...item.values[state])
    }

    if (item.title === tierName) selectedItems.push(...values)
    else if (item.values[state]) selectedItems.push(...item.values[state])
  })

  const availableItems = stateData.filter((item) => !alreadyUsedItems.includes(item))

  let isAll = stateData.length === 0 ? false : true

  availableItems.forEach((item) => {
    if (!values.includes(item)) isAll = false
  })

  return {
    selectedItems: selectedItems.length,
    isAll: isAll,
  }
}

export const sortAndSearchMsa = (
  msaData: IStateMSA[],
  searchKeyWord: string = '',
  sortMsa: number = NaN,
  sortRank: number = NaN,
) => {
  const filterdMsa = msaData.filter((item) => item.msa.name.toLowerCase().includes(searchKeyWord.trim().toLowerCase()))

  if (sortMsa) {
    filterdMsa.sort((x, y) => {
      const a = x.msa.name
      const b = y.msa.name

      if (a < b) return sortMsa * -1
      if (a > b) return sortMsa * 1
      return 0
    })
  } else if (sortRank) {
    filterdMsa.sort((x, y) => {
      const a = x.msa.ranking
      const b = y.msa.ranking

      if (a < b) return sortRank * -1
      if (a > b) return sortRank * 1
      return 0
    })
  }

  const resData = {
    total: filterdMsa.length,
    msa: filterdMsa,
  }

  return resData
}

export const checkMsaTierValue = (
  allTiers: IMsaTier[] | IExperienceMsaTier[],
  values: IStateMSA[],
  tierName: string,
  rank: number,
) => {
  let existTierName

  for (let index = 0; index < allTiers.length; index++) {
    const isExist = allTiers[index].values.find((data) => data.msa.ranking === rank)

    if (isExist) {
      existTierName = allTiers[index].title
      break
    }
  }

  if (existTierName === tierName || !existTierName) {
    const isExist = values.find((item) => item.msa.ranking === rank)

    if (isExist) return true
    return false
  } else return true
}

export const checkMsaTierDisableValue = (
  allTiers: IMsaTier[] | IExperienceMsaTier[],
  tierName: string,
  rank: number,
) => {
  let disabled = false

  for (let index = 0; index < allTiers.length; index++) {
    const isExist = allTiers[index].values.find((data) => data.msa.ranking === rank)

    if (isExist && allTiers[index].title !== tierName) {
      disabled = true
      break
    }
  }

  return disabled
}

export const getMsaAllAvailableItems = (
  allTiers: IMsaTier[] | IExperienceMsaTier[],
  msaData: IStateMSA[],
  tierName: string,
) => {
  const alreadyUsedItems: number[] = []

  allTiers.forEach((item) => {
    if (item.title !== tierName) item.values?.forEach((data) => alreadyUsedItems.push(data.msa.ranking))
  })

  const availableItems = msaData.filter((item) => !alreadyUsedItems.includes(item.msa.ranking))

  return availableItems
}

export const parseMsaValues = (
  data: IMsaTier[] | IExperienceMsaTier[],
  values: IStateMSA[],
  tierName: string,
  totalNum: number,
) => {
  let count = 0

  data.forEach((item) => {
    if (item.title !== tierName) count += item.values.length
  })

  return count + values.length === totalNum ? true : false
}

export const convertNullValue = (value: any) => {
  return isEmpty(value) || isNaN(value) ? 'N/A' : value
}

export const convertNullToBlank = (value: any) => {
  return isEmpty(value) || isNaN(value) ? '' : value
}

export const convertNullToBlankValueToDecimal = (value: any) => {
  return isEmpty(value) || isNaN(value) ? '' : getPrice3decimal(value)
}

export const convertNullToPlus = (value: any) => {
  return isEmpty(value) || isNaN(value) ? '+' : getPrice3decimal(value)
}

export const convertNullToNoScore = (value: any) => {
  return isEmpty(value) || isNaN(value) ? 'No Score' : value
}

export const convertNullToNoMax = (value: any) => {
  return isEmpty(value) || isNaN(value) ? 'No Max' : getPrice3decimal(value)
}

export const validateMsaLtvPropertyType = (data: IPropertyTypeLtvAdj[]) => {
  const errors: string[] = []
  let hasError = false

  data.forEach((item, index) => {
    errors.push('')

    if (
      item.unitsRange.from === null ||
      isNaN(item.unitsRange.from) ||
      item.unitsRange.to === null ||
      isNaN(item.unitsRange.to)
    )
      if (!NoUnitPropertyType.includes(item.propertyType)) {
        errors[index] = 'Invalid Number of Units range'
        hasError = true
      }

    if (isEmpty(item.propertyType)) {
      errors[index] = 'Choose Property Type'
      hasError = true
    }
  })

  return { errors, hasError }
}

export const validateNumberOfUnitsRange = (data: IPropertyTypeLtvAdj[], index: number, from: number, to: number) => {
  let rlt = true

  data.forEach((item, id) => {
    if (
      id !== index &&
      item.unitsRange.from !== null &&
      !isNaN(item.unitsRange.from) &&
      item.unitsRange.to !== null &&
      !isNaN(item.unitsRange.to)
    )
      if (
        (item.unitsRange.from > from && item.unitsRange.from <= to) ||
        (item.unitsRange.from <= from && item.unitsRange.to >= from)
      )
        rlt = false
  })

  return rlt
}
