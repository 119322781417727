import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { IHistory, IProgram, IStaticTable } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  addStaticTable,
  addStaticTableRow,
  changeStaticTableRowsOrder,
  changeStaticTableTitle,
  removeStaticTable,
  removeStaticTableRow,
  updateStaticTableRow,
} from 'services/apis/staticTable'
import { Button } from 'stories/components'
import { confirm, prompt } from 'utils'

import { LoadingStatus } from '.'

interface IProps {
  loanId: number
  program: IProgram
  loading: string
  onSave: (data: IStaticTable[], history: IHistory) => void
}

export const StaticTable = (props: IProps) => {
  const { loanId, program, loading, onSave } = props

  const [data, setData] = useState<IStaticTable[]>([])

  useEffect(() => {
    setData(program?.StaticTable || [])
  }, [program])

  const onAddTable = async () => {
    const title = await prompt('Add New Table', {
      placeholder: 'New Table Title',
    })
    if (!title) return
    if (data.find((item) => item.title === title)) {
      toast(`The same table already exists`, { type: 'error' })
      return
    }

    const res = await addStaticTable(loanId, program.ID, title)

    const newData = cloneDeep(data)
    newData.push({
      title,
      values: [],
    })
    setData(newData)
    onSave(newData, res.newHistory)
  }

  const onEditTitle = async (index: number, title: string) => {
    const newTitle = await prompt('Update Table Title', {
      placeholder: 'New Table Title',
      value: title,
    })
    if (!newTitle) return

    if (data.find((item, id) => id !== index && item.title === newTitle)) {
      toast(`The same table already exists`, { type: 'error' })
      return
    }

    const res = await changeStaticTableTitle(loanId, program.ID, index, newTitle)

    const newData = cloneDeep(data)
    newData[index].title = newTitle
    setData(newData)
    onSave(newData, res.newHistory)
  }

  const onDeleteTable = async (index: number, title: string) => {
    const value = await confirm('Do you want to remove this entire table?', {
      title: title,
    })

    if (!value) return

    const res = await removeStaticTable(loanId, program.ID, index)

    const newData = cloneDeep(data)
    newData.splice(index, 1)
    setData(newData)
    onSave(newData, res.newHistory)
  }

  const onAddRow = async (index: number) => {
    const value = await prompt('Add New Row', {
      placeholder: 'New Row Value',
    })
    if (!value) return

    const res = await addStaticTableRow(loanId, program.ID, index, value)

    const newData = cloneDeep(data)
    newData[index].values.push(value)
    setData(newData)
    onSave(newData, res.newHistory)
  }

  const onEditRow = async (index: number, item: IStaticTable, id: number) => {
    const value = await prompt('Edit Row value', {
      placeholder: 'New Row Value',
      value: item.values[id],
    })
    if (!value) return

    const res = await updateStaticTableRow(loanId, program.ID, index, id, value)

    const newData = cloneDeep(data)
    newData[index].values[id] = value
    setData(newData)
    onSave(newData, res.newHistory)
  }

  const onDeleteRow = async (index: number, item: IStaticTable, id: number) => {
    const value = await confirm('Do you want to remove this row?', {
      title: item.values[id],
    })
    if (!value) return

    const res = await removeStaticTableRow(loanId, program.ID, index, id)

    const newData = cloneDeep(data)
    newData[index].values.splice(id, 1)
    setData(newData)
    onSave(newData, res.newHistory)
  }

  const onUpdateSort = async (index: number, orgIndex: number, newIndex: number) => {
    newIndex -= 1
    if (orgIndex == newIndex) return

    const res = await changeStaticTableRowsOrder(loanId, program.ID, index, orgIndex, newIndex)

    const newData = cloneDeep(data)
    const value = newData[index].values.splice(orgIndex, 1)
    newData[index].values.splice(newIndex, 0, ...value)
    setData(newData)
    onSave(newData, res.newHistory)
  }

  return (
    <div className="relative max-w-screen-xl m-auto w-full flex flex-col gap-6 mb-5 shadow1 rounded pt-4 pb-5 px-4">
      <LayoutLoading show={loading === LoadingStatus.UPDATE_PROGRAM_INFO} />
      <p className="border-b text-lg font-variation-settings-600">Conditions</p>
      {data.map((item, index) => (
        <div key={index} className="w-full overflow-auto shadow-md rounded">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-[14px] text-gray-900 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 text-center">
              <tr>
                <th colSpan={2} className={`p-3 border`}>
                  <span className={`flex gap-2 items-center`}>
                    <span className="flex bg-transparent font-bold text-left text-[16px] capitalize font-variation-settings-600">
                      {item.title}
                    </span>

                    <span className="flex">
                      <span
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded transition-all duration-200"
                        onClick={() => onEditTitle(index, item.title)}
                      >
                        <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                      </span>
                      <span
                        className="text-red-800 p-1 hover-shadow1 cursor-pointer rounded transition-all duration-200"
                        onClick={() => onDeleteTable(index, item.title)}
                      >
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                    </span>
                  </span>
                </th>

                <th scope="col" className="px-3 py-3 w-32">
                  <button
                    className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline"
                    onClick={() => onAddRow(index)}
                  >
                    + Add
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {item.values.map((v, id) => {
                const orderOptions = item.values.map((_, idx) => (idx + 1).toString())
                return (
                  <tr
                    className={`border-b dark:bg-gray-800 dark:border-gray-700 ${id % 2 && 'bg-slate-50'}`}
                    key={`${index}-${id}`}
                  >
                    <td className="p-2 w-10 text-center">{id + 1}</td>
                    <td className="p-2">
                      <pre className="whitespace-pre-wrap">{v}</pre>
                    </td>

                    <td className="p-2">
                      <div className="flex justify-center items-center gap-1.5">
                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded transition-all duration-200"
                          onClick={() => onEditRow(index, item, id)}
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </span>
                        <span
                          className="text-red-800 p-1 hover-shadow1 cursor-pointer rounded transition-all duration-200"
                          onClick={() => onDeleteRow(index, item, id)}
                        >
                          <TrashIcon className="w-4 h-4"></TrashIcon>
                        </span>
                        <select
                          onChange={(e) => onUpdateSort(index, id, Number(e.target.value))}
                          value={id + 1}
                          className="rounded py-1.5 pl-2 pr-9 w-fit text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                        >
                          {orderOptions.map((val) => (
                            <option key={val}>{val}</option>
                          ))}
                        </select>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      ))}

      <div className="inline">
        <Button size="sm" className="mb-0" onClick={() => onAddTable()}>
          + Add
        </Button>
      </div>
    </div>
  )
}
