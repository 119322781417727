import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType, IProgram } from 'config'
import { useEffect, useState } from 'react'
import { RenderInput } from 'utils'

import { LoadingStatus } from '..'
import { defaultInputs } from './constants'

interface IProps {
  program: IProgram
  loading: string
  onSave: (data: any, key: string) => void
}

export const InvestorInfo = (props: IProps) => {
  const { program, loading, onSave } = props

  const [inputs, setInputs] = useState<Record<string, InputType>>({})

  useEffect(() => {
    const newInputs = cloneDeep(defaultInputs())
    Object.keys(newInputs).forEach((key) => {
      newInputs[key].value = (program as any)[key] || ''
    })
    setInputs(newInputs)
  }, [program])

  const onChange = (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    value = value.trim()
    if (newInputs[key].value === value) return

    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onBlur = (key: string) => {
    onSave(inputs[key].value, key)
  }

  return (
    <div className="relative">
      <LayoutLoading show={loading === LoadingStatus.UPDATE_PROGRAM_INFO} />
      <p className="text-lg font-variation-settings-600 mb-3">Investor Info</p>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          return (
            <div key={index} className="col-span-1">
              <RenderInput input={input} Key={key} onChange={onChange} onBlur={onBlur} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
