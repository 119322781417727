import cloneDeep from 'clone-deep'
import type { IProgram } from 'config'
import { useState } from 'react'
import { Modal } from 'stories/components'

import { LoadingStatus } from '..'

export const ProgramsOrderModal = (props: any) => {
  const [programs, setPrograms] = useState<IProgram[]>(props.programs)

  const onChangeOrder = (val: string, index: number) => {
    const nth = Number(val) - 1
    if (nth === index) return

    const newValues = cloneDeep(programs)
    const removedOne = newValues.splice(index, 1)
    newValues.splice(nth, 0, removedOne[0])
    setPrograms(newValues)
  }

  return (
    <div>
      <Modal
        title="Change Programs Order"
        titleOkay="Confirm"
        isOpen={props.isOpen}
        loading={props.loading === LoadingStatus.CHANGE_PROGRAMS_ORDER}
        onClose={props.onClose}
        onOk={() => props.onSubmit(programs)}
      >
        <div className="text-gray-700">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-3 py-3">
                  No
                </th>
                <th scope="col" className="px-3 py-3">
                  Name
                </th>
                <th scope="col" className="px-3 py-3">
                  Type
                </th>
                <th scope="col" className="px-3 py-3">
                  Order
                </th>
              </tr>
            </thead>
            <tbody>
              {programs.map((item, index) => {
                const orderOptions = programs.map((_, idx) => (idx + 1).toString())

                return (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {index + 1}
                    </td>
                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.Name}
                    </td>
                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.Type}
                    </td>
                    <td
                      scope="row"
                      className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap w-[80px]"
                    >
                      <select
                        onChange={(e) => onChangeOrder(e.target.value, index)}
                        value={index + 1}
                        className="block rounded py-1.5 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                      >
                        {orderOptions.map((val) => (
                          <option key={val}>{val}</option>
                        ))}
                      </select>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  )
}
