import { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    Investor: {
      title: 'Investor',
      inputType: 'text',
      error: '',
    },
    InvestorProduct: {
      title: 'Investor Product',
      inputType: 'text',
      error: '',
    },
  }
}
