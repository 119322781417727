import cloneDeep from 'clone-deep'
import { CommercialProdutTypes, InputType } from 'config'
import { useState } from 'react'
import { Modal } from 'stories/components'
import { InputValidate, RenderInput } from 'utils'

import { LoadingStatus } from '..'

const defaultInputs = (): Record<string, InputType> => {
  return {
    Name: {
      title: 'Program Name',
      inputType: 'text',
      error: '',
      required: true,
    },
    Type: {
      title: 'Program Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: CommercialProdutTypes,
      error: '',
      required: true,
    },
  }
}

interface IProps {
  isOpen: boolean
  loading: string
  onClose: () => void
  onSubmit: Function
}

export const NewProgramModal = (props: IProps) => {
  const { isOpen, loading, onClose, onSubmit } = props
  const [inputs, setInputs] = useState(defaultInputs())

  const onChange = (key: string, value: any) => {
    const nInputs = cloneDeep(inputs)
    nInputs[key].value = value
    nInputs[key].error = ''
    setInputs(nInputs)
  }

  const onOk = () => {
    const nInputs = cloneDeep(inputs)
    let hasError = false
    const data: Record<string, any> = {}
    Object.keys(inputs).forEach((key) => {
      const error = InputValidate(inputs[key])
      if (error) {
        hasError = true
        nInputs[key].error = error
      }
      data[key] = typeof inputs[key].value === 'string' ? inputs[key].value.trim() : inputs[key].value
    })
    setInputs(nInputs)
    if (hasError) return
    onSubmit(data)
  }

  return (
    <div>
      <Modal
        title="Add New Program"
        titleOkay="Add"
        isOpen={isOpen}
        loading={loading === LoadingStatus.GET_RATESHEET}
        onClose={onClose}
        onOk={onOk}
      >
        <div className="w-[400px] grid grid-cols-1 gap-2">
          {Object.keys(inputs).map((key, index) => {
            const input = inputs[key]

            return (
              <div key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </Modal>
    </div>
  )
}
