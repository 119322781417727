import { ArchiveBoxXMarkIcon, ListBulletIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import {
  type ILoanPurposeLTVValues,
  type IMsaTier,
  type IPropertyTypeLtvAdj,
  type IStateMSA,
  propertyTypeLabels,
} from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Select2, Tooltip } from 'stories/components'
import { confirm, getPrice3decimal, isEmpty, removeComma } from 'utils'
import { renderHeader } from 'utils/table'

import { convertNullValue, sortAndSearchMsa, validateMsaLtvPropertyType, validateNumberOfUnitsRange } from '../logic'
import { AddTierModal } from '../Modal/AddTierModal'
import { MsaTierValueModal } from '../Modal/MsaTierValueModal'
import { TierLtvAdjustment } from '../TierLtvAdjustment'
import { UpdateAivArvValueRange } from './UpdateAivArvValueRange'

interface IProps {
  isTemplate: boolean
  tiers: IMsaTier[]
  loading: LoadingStatus
  isCommercial: boolean
  onAdd: (tierName: string, key: string) => Promise<boolean>
  onRemove: (tierName: string, key: string) => Promise<boolean>
  onUpdateTier: (originalName: string, tierName: string, key: string) => Promise<boolean>
  onSaveTierValues: (tierName: string, key: string, state: string, values: IStateMSA[]) => Promise<boolean>
  onSaveLtvAdj: (tierName: string, ltvAdj: IPropertyTypeLtvAdj[]) => Promise<boolean>
  onRemoveLtvAdjCondition: (tierName: string, index: number) => void
}

export interface ISelectedRangeData {
  from: number
  to: number
  index: number
  key: 'aivValue' | 'arvValue' | 'unitsRange'
}

const headerList = [
  // {
  //   title: 'Rank',
  //   key: 'sortRank',
  // },
  {
    title: 'MSA Name',
    key: 'sortMsa',
  },
]

const defaultSortOrder = {
  // sortRank: 1,
  sortMsa: 1,
}

// export const propertyOptions: Record<string, string> = {
//   SFR: 'Single Family',
//   Condo: 'Condo',
//   '2-4 units': '2-4 Units',
//   PUD: 'PUD',
//   'Mixed Use': 'Mixed-Use',
//   '5+ units': 'Multifamily 5+',
// }

// export const NoUnitPropertyType = ['SFR', 'Condo', 'PUD']
export const NoUnitPropertyType = [
  'SFR-Detached',
  'SFR-Attached',
  'Condo-Warrantable',
  'Condo-NonWarrantable',
  'Condotel',
  'PUD',
  'Modular',
  'Industrial',
  'Manufactured',
  'Self Storage',
  'Office',
  'Retail',
  'Warehouse',
  'Automotive',
]

export const MsaTiers = (props: IProps) => {
  const {
    isTemplate,
    tiers,
    loading,
    isCommercial,
    onAdd,
    onRemove,
    onUpdateTier,
    onSaveTierValues,
    onSaveLtvAdj,
    onRemoveLtvAdjCondition,
  } = props

  const [modal, setModal] = useState('')
  const [selectedTier, setSelectedTier] = useState<IMsaTier>()
  const [sortOrder, setSortOrder] = useState<Record<string, number>>(defaultSortOrder)
  const [edit, setEdit] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [selectedRange, setSelectedRange] = useState<ISelectedRangeData | undefined>(undefined)

  useEffect(() => {
    if (!tiers) {
      setSelectedTier(undefined)
      return
    }

    if (tiers.length > 0) {
      if (selectedTier) {
        const newTierData = tiers.find((item) => item.title === selectedTier.title)

        if (!newTierData) setSelectedTier(tiers[0])
        else setSelectedTier(newTierData)
      } else setSelectedTier(tiers[0])
    } else setSelectedTier(undefined)

    setErrors([])
    setEdit(false)
  }, [tiers])

  const onAddTier = async (tierName: string) => {
    if (!tierName.trim()) {
      toast('Tier Name is required', {
        type: 'error',
      })

      return
    }
    if (!isEmpty(tiers)) {
      const isSameName = tiers.find((item) => item.title === tierName.trim())

      if (isSameName) {
        toast('This Tier Name already exists', {
          type: 'error',
        })

        return
      }
    }

    const res = await onAdd(tierName, 'msa')

    if (res) setModal('')
  }

  const onUpdate = async (originName: string, tierName: string) => {
    if (originName === tierName.trim()) {
      setModal('')
      return true
    }
    if (!isEmpty(tiers)) {
      const isSameName = tiers.find((item) => item.title === tierName.trim())

      if (isSameName && originName !== tierName) {
        toast('This Tier Name already exists', {
          type: 'error',
        })

        return false
      }
    }

    const res = await onUpdateTier(originName, tierName, 'msa')

    if (res) setModal('')

    return res
  }

  const onRemoveTier = async () => {
    if (!selectedTier) return

    if (!isEmpty(tiers)) {
      const isSameName = tiers.find((item) => item.title === selectedTier.title)

      if (!isSameName) {
        toast(`This Tier doesn't exist`, {
          type: 'error',
        })

        return
      }
    }

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this Tier?
        <br />
        <span className="text-gray-600 text-base">Tier Name: {selectedTier.title}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const res = await onRemove(selectedTier.title, 'msa')

    if (res) setModal('')
  }

  const onSort = (key: string, order: number) => {
    setSortOrder({ ...defaultSortOrder, [key]: order })

    if (!selectedTier) return

    const nSelectedTier = cloneDeep(selectedTier)

    let res

    if (key === 'sortMsa') res = sortAndSearchMsa(nSelectedTier.values, '', order, NaN)
    // if (key === 'sortRank') res = sortAndSearchMsa(nSelectedTier.values, '', NaN, order)

    if (res) {
      nSelectedTier.values = res.msa
      setSelectedTier(nSelectedTier)
    }
  }

  const onSave = async (tierName: string, key: string, values: IStateMSA[]) => {
    const res = await onSaveTierValues(tierName, key, '', values)

    if (res) setModal('')
  }

  const onAddCondition = () => {
    if (!selectedTier) return

    const newTier = cloneDeep(selectedTier)

    newTier.propertyTypeLtvAdj.unshift({
      propertyType: '',
      unitsRange: { from: NaN, to: NaN },
      aivValue: { from: 0, to: 0 },
      arvValue: { from: 0, to: 0 },
      ltvAdjustment: {
        purchase: {
          aivLtv: 0,
          arvLtv: 0,
          ltc: 0,
          ltp: 0,
        },
        nocashout: {
          aivLtv: 0,
          arvLtv: 0,
          ltc: 0,
          ltp: 0,
        },
        cashout: {
          aivLtv: 0,
          arvLtv: 0,
          ltc: 0,
          ltp: 0,
        },
      },
    })

    setSelectedTier(newTier)
    setEdit(true)
  }

  const onChangeLtvAdj = (index: number, ltvAdj: ILoanPurposeLTVValues) => {
    if (!selectedTier) return

    const newTier = cloneDeep(selectedTier)

    newTier.propertyTypeLtvAdj[index].ltvAdjustment = ltvAdj

    setSelectedTier(newTier)
    setEdit(true)
  }

  const onChangePropertyType = (index: number, value: string) => {
    if (!selectedTier) return

    const newTier = cloneDeep(selectedTier)

    newTier.propertyTypeLtvAdj[index].propertyType = value

    if (NoUnitPropertyType.includes(value)) newTier.propertyTypeLtvAdj[index].unitsRange = { from: NaN, to: NaN }

    const errorsData = validateMsaLtvPropertyType(newTier.propertyTypeLtvAdj)

    setErrors(errorsData.errors)
    setSelectedTier(newTier)
    setEdit(true)
  }

  const onChangeRange = async (
    index: number,
    key: 'aivValue' | 'arvValue' | 'unitsRange',
    from: number,
    to: number,
  ) => {
    if (!selectedTier) return

    const newTier = cloneDeep(selectedTier)

    newTier.propertyTypeLtvAdj[index][key] = { from: removeComma(from), to: removeComma(to) }

    if (key === 'unitsRange') {
      const isValid = validateNumberOfUnitsRange(newTier.propertyTypeLtvAdj, index, removeComma(from), removeComma(to))

      if (!isValid) {
        toast(`Invalid range`, {
          type: 'error',
        })
        return
      }

      newTier.propertyTypeLtvAdj[index][key] = {
        from: removeComma(from) ? removeComma(from) : NaN,
        to: removeComma(to) ? removeComma(to) : NaN,
      }
    }

    const errorsData = await validateMsaLtvPropertyType(newTier.propertyTypeLtvAdj)

    setErrors(errorsData.errors)

    setSelectedTier(newTier)
    setEdit(true)
    setModal('')
  }

  const onSaveLtvAdjustment = async () => {
    if (!selectedTier) return

    const errorsData = await validateMsaLtvPropertyType(selectedTier.propertyTypeLtvAdj)

    setErrors(errorsData.errors)

    if (errorsData.hasError) return

    const res = await onSaveLtvAdj(selectedTier.title, selectedTier.propertyTypeLtvAdj)

    if (res) setEdit(false)
  }

  const onRemoveMsaCondition = async (index: number) => {
    if (!selectedTier) return

    if (edit) {
      const newTier = cloneDeep(selectedTier)

      newTier.propertyTypeLtvAdj.splice(index, 1)

      setSelectedTier(newTier)
    } else {
      const content = (
        <div className="text-gray-400 mb-4 text-[18px]">
          Do you want to delete this condition?
          <br />
          <span className="text-gray-600 text-base">Condition Number: {index + 1}</span>
        </div>
      )

      const result = await confirm(content)

      if (!result) return

      await onRemoveLtvAdjCondition(selectedTier.title, index)
    }
  }

  const renderPropertyLtvAdjustment = () => {
    if (!selectedTier) return <></>

    if (!selectedTier?.propertyTypeLtvAdj || !selectedTier?.propertyTypeLtvAdj.length)
      return (
        <div className="w-full flex justify-center items-center mt-6">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <div className="my-3">
        {selectedTier.propertyTypeLtvAdj &&
          selectedTier.propertyTypeLtvAdj.length > 0 &&
          selectedTier.propertyTypeLtvAdj.map((item, index) => (
            <div key={index} className="shadow1 p-3 rounded w-full my-3">
              {isTemplate && (
                <div className="flex justify-end">
                  <div
                    className="p-1 hover-shadow1 transition-all duration-200 cursor-pointer rounded"
                    onClick={() => onRemoveMsaCondition(index)}
                  >
                    <TrashIcon className="w-5 h-5 text-red-600" />
                  </div>
                </div>
              )}

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-2 mb-3">
                <Select2
                  id="PropertyType"
                  title="Property Type"
                  options={propertyTypeLabels}
                  value={item.propertyType}
                  hasDefaultOption={true}
                  readOnly={!isTemplate}
                  error={errors[index]}
                  onChange={(value) => onChangePropertyType(index, value)}
                />

                <table className="table text-sm text-center w-full">
                  <tbody>
                    <tr className="bg-gray-100">
                      <th className="border">Number of Units</th>
                    </tr>
                    <tr>
                      <td className="border">
                        <span
                          className={`${
                            isTemplate && !NoUnitPropertyType.includes(item.propertyType)
                              ? 'cursor-pointer hover:border-b border-b-stone-700'
                              : ''
                          }`}
                          onClick={() => {
                            if (!isTemplate || NoUnitPropertyType.includes(item.propertyType)) return

                            setSelectedRange({
                              from: item.unitsRange.from,
                              to: item.unitsRange.to,
                              index: index,
                              key: 'unitsRange',
                            })
                            setModal('updateRange')
                          }}
                        >
                          {convertNullValue(item.unitsRange.from)} ― {convertNullValue(item.unitsRange.to)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table text-sm text-center w-full">
                  <tbody>
                    <tr className="bg-gray-100">
                      <th className="border">As Is Appraised Value</th>
                    </tr>
                    <tr>
                      <td className="border">
                        <span
                          className={`${isTemplate ? 'cursor-pointer hover:border-b border-b-stone-700' : ''}`}
                          onClick={() => {
                            if (!isTemplate) return

                            setSelectedRange({
                              from: item.aivValue.from,
                              to: item.aivValue.to,
                              index: index,
                              key: 'aivValue',
                            })
                            setModal('updateRange')
                          }}
                        >
                          {getPrice3decimal(item.aivValue.from)} ― {getPrice3decimal(item.aivValue.to)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table text-sm text-center w-full">
                  <tbody>
                    <tr className="bg-gray-100">
                      <th className="border">After Repair Value</th>
                    </tr>
                    <tr>
                      <td className="border">
                        <span
                          className={`${
                            isTemplate && isCommercial ? 'cursor-pointer hover:border-b border-b-stone-700' : ''
                          }`}
                          onClick={() => {
                            if (!isTemplate) return

                            if (isCommercial) {
                              setSelectedRange({
                                from: item.arvValue.from,
                                to: item.arvValue.to,
                                index: index,
                                key: 'arvValue',
                              })
                              setModal('updateRange')
                            }
                          }}
                        >
                          {getPrice3decimal(item.arvValue.from)} ― {getPrice3decimal(item.arvValue.to)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <TierLtvAdjustment
                loading={loading}
                isTemplate={isTemplate}
                ltvAdjustment={item.ltvAdjustment}
                isCommercial={isCommercial}
                isMsa={true}
                onSave={() => {}}
                onChange={(ltvAdj) => onChangeLtvAdj(index, ltvAdj)}
              />
            </div>
          ))}
      </div>
    )
  }

  const renderTierContent = useMemo(() => {
    if (!selectedTier)
      return (
        <div className="w-full flex justify-center items-center">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Tiers</span>
          </div>
        </div>
      )

    return (
      <div>
        {isTemplate && (
          <div className="flex justify-end px-2 mb-1">
            <Tooltip message="Edit Tier Name">
              <div
                className="text-shade-blue p-1 transition-all duration-200 hover-shadow1 cursor-pointer rounded mr-2"
                onClick={() => setModal('editTier')}
              >
                <PencilSquareIcon className="w-5 h-5" />
              </div>
            </Tooltip>

            <Tooltip message="Select MSAs">
              <div
                className="text-shade-blue p-1 transition-all duration-200 hover-shadow1 cursor-pointer rounded mr-2"
                onClick={() => setModal('editTierValue')}
              >
                <ListBulletIcon className="w-5 h-5" />
              </div>
            </Tooltip>

            <Tooltip message="Remove Tier">
              <div
                className="p-1 hover-shadow1 transition-all duration-200 cursor-pointer rounded"
                onClick={() => onRemoveTier()}
              >
                <TrashIcon className="w-5 h-5 text-red-600" />
              </div>
            </Tooltip>
          </div>
        )}

        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6 mb-4">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              {headerList.map((item: Record<string, string>, index: number) => {
                return renderHeader({
                  title: item.title,
                  key: item.key,
                  index: index,
                  onSort: (key: string, order: number) => onSort(key, order),
                  sortable: true,
                  sortOrder: sortOrder[item.key],
                })
              })}
              <th scope="col" className="px-3 py-3">
                States MSA
              </th>
            </tr>
          </thead>

          <tbody>
            {selectedTier && Array.isArray(selectedTier.values) && !!selectedTier.values.length ? (
              selectedTier.values.map((item, index) => {
                const statesMsa = item.states.join('-') + ' MSA'
                return (
                  <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={index}>
                    <td scope="row" className="px-3 py-3">
                      {index + 1}
                    </td>
                    {/* <td scope="row" className="px-3 py-3">
                      {item.msa.ranking}
                    </td> */}
                    <td scope="row" className="px-3 py-3">
                      {item.msa.name}
                    </td>
                    <td scope="row" className="px-3 py-3">
                      {statesMsa}
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td scope="row" colSpan={4} className="p-3 text-center">
                  No MSAs
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {isTemplate && (
          <div className={`flex items-center ${edit ? 'justify-between' : 'justify-end'}`}>
            {edit && (
              <Button
                className="text-[14px] pt-1.5 pb-1.5 mb-[-1px] transition-all duration-200"
                onClick={onSaveLtvAdjustment}
              >
                Save
              </Button>
            )}

            <div
              className="flex items-center hover:cursor-pointer text-shade-blue hover:border-b transition-all duration-200 hover:border-b-shade-blue mr-4 pb-1 pr-1 h-6"
              onClick={onAddCondition}
            >
              <PlusIcon className="w-4 h-4 mr-1" />
              <span>Add LTV Limit</span>
            </div>
          </div>
        )}

        {renderPropertyLtvAdjustment()}
      </div>
    )
  }, [selectedTier, errors, isTemplate])

  return (
    <div className="relative h-full">
      <LayoutLoading
        show={[
          LoadingStatus.ADD_TIER,
          LoadingStatus.REMOVE_TIER,
          LoadingStatus.TIER_VALUES,
          LoadingStatus.MSA_TIER_CONDITION,
        ].includes(loading)}
      />

      {isCommercial && (
        <div className="text-lg font-variation-settings-600 mb-3">
          <span>MSA Tiers</span>
          {tiers && tiers.length > 0 && <span className="ml-1 text-base">({tiers.length})</span>}
        </div>
      )}

      <div className="flex items-center flex-wrap mb-1">
        {tiers &&
          tiers.length > 0 &&
          tiers.map((tier, index: number) => (
            <Button
              color={`${selectedTier?.title === tier.title ? 'blue' : 'gray'}`}
              className="text-[14px] pt-[6px] pb-[6px] transition-all duration-200"
              key={index}
              onClick={() => {
                setErrors([])
                setEdit(false)
                setSelectedTier(tier)
              }}
            >
              {tier.title}
            </Button>
          ))}

        {isTemplate && (
          <Button
            color="gray"
            className="pt-[7px] pb-[7px] transition-all duration-200"
            onClick={() => setModal('addTier')}
          >
            <PlusIcon className="w-5 h-5" />
          </Button>
        )}
      </div>

      {renderTierContent}

      {['addTier', 'editTier'].includes(modal) && (
        <AddTierModal
          name={modal === 'editTier' ? selectedTier?.title || '' : ''}
          isOpen={['addTier', 'editTier'].includes(modal)}
          loading={loading}
          onClose={() => setModal('')}
          onSave={onAddTier}
          onUpdate={onUpdate}
        />
      )}

      {modal === 'editTierValue' && selectedTier && (
        <MsaTierValueModal
          isTemplate={isTemplate}
          allTiers={tiers}
          tierData={selectedTier}
          loading={loading}
          isOpen={modal === 'editTierValue'}
          onClose={() => setModal('')}
          onSubmit={onSave}
        />
      )}
      {modal === 'updateRange' && selectedRange && (
        <UpdateAivArvValueRange
          data={selectedRange}
          isOpen={modal === 'updateRange'}
          onClose={() => setModal('')}
          onSave={onChangeRange}
        />
      )}
    </div>
  )
}
