// import React from 'react'

import { MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { IProgram, itemCountPerPage } from 'config'
import { ILoanProgram, ILoanProgramValidateData } from 'pages/AdminTools/LoanPrograms/interface'
import LoanProgramForm from 'pages/AdminTools/LoanPrograms/LoanProgramForm'
import { useEffect, useMemo, useState } from 'react'
import { deleteLoanProgram, getCompanyProductsPrograms, getLoanProgramsByProgramID } from 'services'
import { Button, Input2, Pagination } from 'stories/components'
import { confirm, formatDateYMD } from 'utils'
import { renderHeader } from 'utils/table'

import { ProgramTypes } from '.'

const defaultSortOrder = {
  no: 1,
  programCode: 1,
  productName: 1,
  programID: -1,
  mortgageType: 1,
  amortizationType: 1,
  createdAt: 1,
}

const headerList = [
  {
    title: 'No',
    key: 'no',
  },
  {
    title: 'Program Code',
    key: 'programCode',
  },
  {
    title: 'Product Type',
    key: 'productName',
  },
  {
    title: 'Program Name',
    key: 'programID',
  },
  {
    title: 'Mortgage Type',
    key: 'mortgageType',
  },
  {
    title: 'Amortization Type',
    key: 'amortizationType',
  },
  {
    title: 'Created At',
    key: 'createdAt',
  },
]

interface IProps {
  companyID: number
  program: IProgram
}

export function LoanProgram(props: IProps) {
  const { companyID, program } = props

  const [query, setQuery] = useState('')
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [sortOrder, setSortOrder] = useState<Record<string, number>>(defaultSortOrder)
  const [order, setOrder] = useState<Record<string, string>>({})
  const [loanPrograms, setLoanPrograms] = useState<ILoanProgram[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [productOptions, setProductOptions] = useState<string[]>([])
  const [programOptions, setProgramOptions] = useState<Record<string, string>>({})
  const [targetProgram, setTargetProgram] = useState<ILoanProgramValidateData>()

  useEffect(() => {
    if (!companyID || !program) return

    getCompanyProductsPrograms(companyID)
      .then(({ products, programs, programsData }) => {
        const targetProgram = programsData.find((item: any) => item.ID === program.ID) as ILoanProgramValidateData
        let nProducuts = cloneDeep(products) as string[]

        if (targetProgram.ProductType === 'DSCR') nProducuts = nProducuts.slice(4)
        else nProducuts = nProducuts.slice(0, 4)
        setProductOptions(nProducuts)
        setProgramOptions(programs)
        setTargetProgram(targetProgram)
      })
      .catch((err) => console.log(err))

    refresh()
  }, [companyID, program])

  const refresh = (pageNum: number = page, orderData: Record<string, string> = order, queryData: string = query) => {
    setIsLoading(true)

    const data = {
      limit: itemCountPerPage,
      page: pageNum + 1,
      order: orderData,
      query: queryData,
    }

    getLoanProgramsByProgramID(companyID, program.ID, data)
      .then(({ loanPrograms, totalNum }) => {
        setTotal(totalNum)
        setLoanPrograms(loanPrograms)
      })
      .finally(() => setIsLoading(false))
  }

  const onSort = async (key: string, orderValue: number) => {
    setSortOrder({ ...defaultSortOrder, [key]: orderValue })
    setOrder({ [key]: orderValue === 1 ? 'ASC' : 'DESC' })

    refresh(page, { [key]: orderValue === 1 ? 'ASC' : 'DESC' })
  }

  const onPageNavigate = async (pageNum: number) => {
    setPage(pageNum)
    await refresh(pageNum)
  }

  const onAdd = () => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(-1)
    setIsEditing(true)
  }

  const onEdit = (index: number) => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(index)
    setIsEditing(true)
  }

  const onTrash = async (index: number) => {
    const { id, no } = loanPrograms[index]
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this Loan Program?
        <br />
        <span className="text-gray-600 text-base">Loan Program No: {no}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setIsLoading(true)

    deleteLoanProgram(companyID, id)
      .then((res) => {
        if (res.success) refresh()
      })
      .finally(() => setIsLoading(false))
  }

  const isFullyAdded = useMemo(() => {
    if (!targetProgram) return false

    let requiredItems = targetProgram.Type === ProgramTypes.COMMERCIAL_ONLY_PROGRAMMED ? 4 : 21
    requiredItems -= loanPrograms.length

    if (requiredItems === 0) return true
    else return false
  }, [targetProgram, loanPrograms])

  if (isEditing)
    return (
      <LoanProgramForm
        companyID={companyID}
        program={program}
        productOptions={productOptions}
        programOptions={programOptions}
        lastUpdatedAt={lastUpdatedAt}
        defaults={selectedIndex == -1 ? null : loanPrograms[selectedIndex]}
        onBack={() => setIsEditing(false)}
        onComplete={refresh}
      />
    )

  return (
    <div className="LoanPrograms-container relative p-2">
      <LayoutLoading show={isLoading} />

      <div className="text-lg font-variation-settings-600 mb-3">Loan Programs ({loanPrograms.length})</div>

      <div className="flex flex-wrap justify-between mb-3">
        <div className="md:w-96 w-72">
          <Input2
            type="search"
            title="Search"
            hasIcon
            icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
            value={query}
            onChange={(value) => {
              setQuery(value)
              setPage(0)
              refresh(0, order, value)
            }}
          />
        </div>

        <div className="w-32">
          <Button full onClick={onAdd} disabled={isFullyAdded}>
            Add
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full min-w-max text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {headerList.map((item: Record<string, string>, index: number) => {
                return renderHeader({
                  title: item.title,
                  key: item.key,
                  index: index,
                  onSort: (key: string, order: number) => onSort(key, order),
                  sortable: true,
                  sortOrder: sortOrder[item.key],
                })
              })}
              <th scope="col" className="px-1">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {loanPrograms.map((program, index) => {
              const { no, programCode, productName, programID, mortgageType, amortizationType, createdAt } = program

              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                  <td scope="row" className="px-2 py-2 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {no}
                  </td>
                  <td className="px-2">{programCode}</td>
                  <td className="px-2">{productName}</td>
                  <td className="px-2">{programOptions[programID]}</td>
                  <td className="px-2">{mortgageType}</td>
                  <td className="px-2">{amortizationType}</td>
                  <td className="px-2">{formatDateYMD(createdAt)}</td>
                  <td className="">
                    <span className="flex px-1">
                      <span
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded-sm transition-all duration-200"
                        onClick={() => onEdit(index)}
                      >
                        <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                      </span>
                      <span
                        className="text-red-800 p-1 hover-shadow1 cursor-pointer rounded-sm transition-all duration-200"
                        onClick={() => onTrash(index)}
                      >
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>

        <div className="flex justify-end">
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={page}
          />
        </div>
      </div>
    </div>
  )
}
