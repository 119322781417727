import { LayoutLoading } from 'components/LayoutLoading'
import type { IProgram } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { Button, ButtonGroup, Input2 } from 'stories/components'
import { isNonDscr, removeComma } from 'utils'

import { LoadingStatus } from '..'
import { LoanAmountBonusMargin } from './LoanAmountBonusMargin'
import { MinMaxPriceRate } from './MinMaxPriceRate'
import { RatePriceLimitWithExitFee } from './RatePriceLimitWithExitFee'

interface IProps {
  program: IProgram
  loading: string
  onSaveBonusMargin: (data: any, key: string) => void
  onSavePriceMarginLimit: (data: any, key: string) => void
}

const menus = {
  loanAmount: 'Loan Amount',
}

export const ProgramMargin = (props: IProps) => {
  const { program, loading, onSaveBonusMargin, onSavePriceMarginLimit } = props

  const [margin, setMargin] = useState('')
  const [origin, setOrigin] = useState('')
  const [edit, setEdit] = useState(false)
  const [selectedMenu, setMenu] = useState(menus.loanAmount)

  const isNonDscrProgram = useMemo(() => {
    return isNonDscr(program)
  }, [program])

  useEffect(() => {
    setMargin(program.Margin.toString())
    setOrigin(program.Margin.toString())
    setEdit(false)
  }, [program])

  const onBlur = () => {
    const originValue = removeComma(origin)
    const newValue = removeComma(margin)

    if (originValue === newValue) return

    setOrigin(margin)
    setEdit(true)
  }

  const renderContent = useMemo(() => {
    switch (selectedMenu) {
      case menus.loanAmount:
        return <LoanAmountBonusMargin program={program} loading={loading} onSave={onSaveBonusMargin} />
      default:
        return <></>
    }
  }, [selectedMenu, loading, program])

  return (
    <div className="relative">
      <LayoutLoading show={loading === LoadingStatus.UPDATE_PROGRAM_INFO} />

      <p className="text-lg font-variation-settings-600 mb-3">Price Margin</p>

      <div className="flex items-center gap-4 mb-8">
        <Input2
          title="Margin"
          type="number"
          className="w-[250px]"
          value={String(margin)}
          onChange={(v) => setMargin(v)}
          onBlur={onBlur}
        />

        {edit && (
          <Button
            loading={loading === LoadingStatus.UPDATE_PROGRAM_INFO}
            className="pt-1.5 pb-1.5 mb-[-2px]"
            onClick={() => {
              setEdit(false)
              onSavePriceMarginLimit(removeComma(margin), 'Margin')
            }}
          >
            Save
          </Button>
        )}
      </div>

      <div className="mb-8">
        {!isNonDscrProgram && (
          <MinMaxPriceRate type="MinMaxRate" program={program} loading={loading} onSave={onSavePriceMarginLimit} />
        )}
      </div>

      <p className="text-lg font-variation-settings-600 mb-1">Bonus Margin</p>
      <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={(value) => setMenu(value)} />

      <div className="p-2">{renderContent}</div>

      <div className="mt-8">
        {isNonDscrProgram && (
          <RatePriceLimitWithExitFee
            data={program.RatePriceLimitWithExitFee || []}
            loading={loading}
            onSave={(data) => onSavePriceMarginLimit(data, 'RatePriceLimitWithExitFee')}
          />
        )}
      </div>
    </div>
  )
}
