import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useState } from 'react'
import { Modal } from 'stories/components'
import { InputValidate, RenderInput } from 'utils'

const defaultInputs = (): Record<string, InputType> => {
  return {
    isNoScore: {
      title: 'No Score',
      inputType: 'checkbox',
      color: 'gray',
      error: '',
      value: false,
      visible: true,
    },
    FICO: {
      title: 'New FICO',
      inputType: 'text',
      error: '',
      type: 'number',
      value: '',
      required: true,
      visible: true,
    },
  }
}

interface IProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: Record<string, any>) => void
}

export const AddNewFICOModal = ({ isOpen, onClose, onSubmit }: IProps) => {
  const [inputs, setInputs] = useState(defaultInputs())

  const onChange = (key: string, value: any) => {
    const nInputs = cloneDeep(inputs)
    nInputs[key].value = value
    nInputs[key].error = ''
    if (key === 'isNoScore') {
      if (!!value) {
        nInputs['FICO'].visible = false
        nInputs['FICO'].disabled = true
      } else {
        nInputs['FICO'].visible = true
        nInputs['FICO'].disabled = false
      }
    }
    setInputs(nInputs)
  }

  const onOk = () => {
    const nInputs = cloneDeep(inputs)
    let hasError = false
    const data: Record<string, any> = {}
    Object.keys(inputs).forEach((key) => {
      const error = InputValidate(inputs[key])
      if (error) {
        hasError = true
        nInputs[key].error = error
      }
      data[key] = typeof inputs[key].value === 'string' ? inputs[key].value.trim() : inputs[key].value
    })
    setInputs(nInputs)
    if (hasError) return
    onSubmit(data)
  }

  return (
    <div>
      <Modal title="Add a new FICO" titleOkay="Add" isOpen={isOpen} onClose={onClose} onOk={onOk}>
        <div className="w-[400px] grid grid-cols-1 gap-4">
          {Object.keys(inputs).map((key, index) => {
            const input = inputs[key]

            return (
              <div key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </Modal>
    </div>
  )
}
