import { LayoutLoading } from 'components/LayoutLoading'
import type { IRateSheet } from 'config'
import { Checkbox } from 'stories/components'

import { LoadingStatus } from '.'

interface IProps {
  ratesheet: IRateSheet
  loading: string
  onChangeLockDays: (index: number) => {}
  onUpdateBankruptcy: () => {}
}

export function LockDaysBankruptcy({ ratesheet, loading, onChangeLockDays }: IProps) {
  return (
    <div className="relative shadow1 rounded max-w-screen-xl m-auto w-full p-4 mb-5">
      <LayoutLoading show={loading === LoadingStatus.UPDATE_RATESHEET_INFO} />

      {ratesheet.lockDays.length > 0 && (
        <div>
          <div className="text-lg font-variation-settings-600 border-b mb-2">Lock Days</div>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 p-2">
            {ratesheet.lockDays.map((item, index: number) => (
              <Checkbox
                key={index}
                id={`${index}-${item.description}`}
                value={item.Power}
                title={`${item.description} days`}
                color="gray"
                disabled={loading === LoadingStatus.UPDATE_RATESHEET_INFO}
                onChange={async () => await onChangeLockDays(index)}
              />
            ))}
          </div>
        </div>
      )}

      {/* <div className="text-lg font-variation-settings-600 border-b mb-2">Bankruptcy</div>

      <div className="p-2">
        <Checkbox
          id="bankruptcy"
          value={ratesheet.bankruptcy}
          title={`If Bankruptcy = "BK Active" then don not show any interest rate and prices`}
          color="gray"
          disabled={loading === LoadingStatus.UPDATE_RATESHEET_INFO}
          onChange={async () => await onUpdateBankruptcy()}
        />
      </div> */}
    </div>
  )
}
