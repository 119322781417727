import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import type { IProgram } from 'config'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { ToggleButton } from 'stories/components'
import { confirm } from 'utils'

import { LoadingStatus } from '.'
import { UpdateProgramNameModal } from './Modals'

interface IProps {
  programs: IProgram[]
  loading: string
  isGeneralRatesheet?: boolean
  onUpdate: (index: number, value: boolean | string, key: string) => {}
  onDelete: (index: number) => {}
}

export function RatesheetPrograms({ programs, loading, isGeneralRatesheet = false, onUpdate, onDelete }: IProps) {
  const [program, setProgram] = useState<IProgram>()
  const [modal, setModal] = useState(false)

  const onRemove = async (index: number, name: string) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure want to delete?
        <br />
        <span className="text-gray-600 text-base">Program Name: {name}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    await onDelete(index)
  }

  const onChangeName = async (name: string) => {
    if (!program) return

    if (program.Name === name) return

    const index = programs.findIndex((item) => item.ID === program.ID)

    const res = await onUpdate(index, name, 'Name')

    if (res) setModal(false)
  }

  const onChangePower = async (index: number, value: boolean, name: string) => {
    const changedProgram = programs.find((item) => item.Name === name)

    if (changedProgram?.Power === value) return

    await onUpdate(index, value, 'Power')
  }

  return (
    <div className="relative shadow1 rounded max-w-screen-xl m-auto w-full px-4 pt-4 pb-5 mb-5">
      <LayoutLoading show={loading === LoadingStatus.UPDATE_PROGRAM_INFO} />

      <div className="text-lg font-variation-settings-600 border-b mb-2">Rate Sheet Programs</div>

      <table className="table w-full">
        <thead className="bg-gray-100">
          <tr>
            <th className="border px-3 py-2">No</th>
            <th className="border px-3 py-2">Name</th>
            <th className="border px-3 py-2">Action</th>
            {!isGeneralRatesheet && <th className="border px-3 py-2">Power</th>}
          </tr>
        </thead>

        <tbody>
          {programs.map((item, index: number) => (
            <tr key={index}>
              <td className="border px-3 py-2 text-center">{index + 1}</td>
              <td className="border px-3 py-2">{item.Name}</td>
              <td className="border px-3 py-2">
                <div className="flex justify-center items-center">
                  <div
                    className="p-1 hover:cursor-pointer hover-shadow1 rounded mr-2"
                    onClick={() => {
                      if (item.Type === 'CommercialDSCR') {
                        toast(`CommercialDSCR Type's Program Name cannot be changed for now`, {
                          type: 'error',
                        })
                        return
                      }
                      setProgram(item)
                      setModal(true)
                    }}
                  >
                    <PencilSquareIcon className="w-4 h-4 text-shade-blue" />
                  </div>

                  <div
                    className="p-1 hover:cursor-pointer hover-shadow1 rounded"
                    onClick={async () => await onRemove(index, item.Name)}
                  >
                    <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                  </div>
                </div>
              </td>
              {!isGeneralRatesheet && (
                <td className="border px-3 py-2">
                  <div className="flex justify-center">
                    <ToggleButton
                      id={`${index}-${item.Name}`}
                      value={item.Power}
                      label={['ON', 'OFF']}
                      onChange={async (value) => await onChangePower(index, value, item.Name)}
                    />
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {modal && (
        <UpdateProgramNameModal
          isOpen={modal}
          loading={loading}
          program={program}
          onClose={() => setModal(false)}
          onSubmit={onChangeName}
        />
      )}
    </div>
  )
}
