import type { IProduct } from 'config'
import { Checkbox } from 'stories/components'

import { LoadingStatus } from '.'

interface IProps {
  products: Record<string, any>
  loading: string
  onSave: (index: number) => void
}

export function RatesheetProducts({ products, loading, onSave }: IProps) {
  return (
    <div>
      {(products.armProducts.IsInterestOnly.length > 0 || products.armProducts.NonInterestOnly.length > 0) && (
        <div>
          <div className="border-b font-variation-settings-600 mb-1">ARMs</div>

          {Object.keys(products.armProducts).map((key: string, index: number) => (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 border-b p-2" key={index}>
              {products.armProducts[key].map((item: IProduct) => (
                <Checkbox
                  key={item.Id}
                  id={`${index}-${item.Id}`}
                  title={item.Description}
                  value={item.Power}
                  color="gray"
                  disabled={loading === LoadingStatus.UPDATE_PRODUCT}
                  onChange={async () => await onSave(item.Id)}
                />
              ))}
            </div>
          ))}
        </div>
      )}

      {(products.fixedProducts.IsInterestOnly.length > 0 || products.fixedProducts.NonInterestOnly.length > 0) && (
        <div>
          <div className="border-b font-variation-settings-600 mt-3">Fixed</div>

          {Object.keys(products.fixedProducts).map((key: string, index: number) => (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 border-b p-2" key={index}>
              {products.fixedProducts[key].map((item: IProduct) => (
                <Checkbox
                  key={item.Id}
                  id={`${index}-${item.Id}`}
                  title={item.Description}
                  value={item.Power}
                  color="gray"
                  disabled={loading === LoadingStatus.UPDATE_PRODUCT}
                  onChange={async () => await onSave(item.Id)}
                />
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
