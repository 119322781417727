import type { ICommercialOnlyProgrammed, IProgram } from 'config'
import { MultifamilyPropertyTypes } from 'pages/RateSheetOverview/ExcelSheets/Dscr/constants'
import { CreditNoScore } from 'pages/RateSheetOverview/ExcelSheets/Dscr/RangeEditModal'
import { getPrice3decimal, isEmpty } from 'utils'

export interface IStyle {
  inputTypeRows: number[]
  LTV: number
  Rates: number
}

class ExcelService {
  constructor() {}
  // Function Excel Parse Normal Program
  parseNormalProgram = (data: IProgram, isPoints: boolean) => {
    return new Promise((resolve, reject) => {
      try {
        let rlt: any[][] = []
        let style: IStyle = {
          inputTypeRows: [],
          LTV: 0,
          Rates: 0,
        }
        let row = ['', 'LTV']
        // LTV parse
        data.LTV.map((item) => {
          row.push(`${item.from} — ${item.to}`)
        })
        style.LTV = data.LTV.length + 2
        style.Rates = data.InterestRate.length
        rlt.push(row)
        // Income Documentation Parse
        data.IncomeDocs.map((item, index) => {
          let rows: any[] = []
          let { IncomeDocItems, LLPA } = item
          rows.push([`IncomeDocs — ${index + 1}`])
          style.inputTypeRows.push(rlt.length)
          LLPA.map((item1, index1) => {
            if (rows[index1 + 1] === undefined) rows.push([''])
            if (item1.Item?.from !== undefined)
              rows[index1 + 1][1] = `${item1.Item.from} ${item1.Item.to === null ? '+' : '— ' + item1.Item.to}`
            else rows[index1 + 1][1] = item1.Item
            item1.LLPA.map((item2, index2) => {
              rows[index1 + 1][2 + index2] = item2
            })

            if (IncomeDocItems[index1] !== undefined) {
              rows[index1 + 1][0] = IncomeDocItems[index1]
            }
            return index1
          })
          rlt = [...rlt, ...rows]
          return index
        })
        // Input Label
        data.LLPAs.map((llpa) => {
          let rows: any = []
          rows.push([llpa.Name])
          style.inputTypeRows.push(rlt.length)
          llpa.Values.map((item, index) => {
            let { Item, LLPA, Range } = item
            if (llpa.ID === 'PropertyType') {
              if (MultifamilyPropertyTypes.includes(Item) && Range) {
                const { from, to } = Range
                const units = from === to ? ` (${from} units)` : ` (${from}-${to} units)`
                Item += units
              }
            } else {
              if (Item?.from !== undefined) {
                if (Item.from == null) {
                  Item = `${llpa.Name}<=${getPrice3decimal(Item.to)}`
                } else if (Item.to == null) {
                  Item = `${getPrice3decimal(Item.from)} +`
                } else {
                  Item = `${getPrice3decimal(Item.from)} — ${getPrice3decimal(Item.to)}`
                }
              }
            }
            if (rows[index + 1] === undefined) rows.push([''])
            rows[index + 1][1] = Item

            LLPA.map((item1, index1) => {
              rows[index + 1][index1 + 2] = item1
              return index1
            })
            return index
          })
          rlt = [...rlt, ...rows]
          return true
        })
        // Base Price
        rlt[0] = [
          ...rlt[0],
          '',
          'Base Rate',
          `ARM ${isPoints ? 'Points' : 'Price'}`,
          `Fixed ${isPoints ? 'Points' : 'Price'}`,
        ]
        data.InterestRate.map((item, index) => {
          if (rlt[index + 1].length < style.LTV) {
            for (let i = rlt[index + 1].length; i < style.LTV; i += 1) {
              rlt[index + 1].push('')
            }
          }
          rlt[index + 1] = [...rlt[index + 1], '', item, data.ArmBasePrice[index], data.FixedBasePrice[index]]
        })
        resolve({
          sheet: rlt,
          style,
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  // Function Excel Parse HardMoney Program

  parseHardMoneyProgram = (data: Record<string, any>) => {
    return new Promise((resolve, reject) => {
      try {
        let rlt: any = []
        Object.keys(data).map((key) => {
          const {
            TierPricing,
            loanAmount,
            minDscr,
            monthsReserve,
            ficos,
            purchaseLTVs,
            purchaseLTCs,
            cashOutLTVs,
            cashOutLTCs,
            TierRates,
          } = data[key]
          let row = [
            key,
            '',
            '',
            '',
            '',
            'No Income',
            '',
            '',
            '',
            '',
            '',
            TierPricing[1],
            TierPricing[2],
            TierPricing[3],
          ]
          rlt.push(row)
          row = ['', '', '', '', '', 'Purch-R/T-Reno', '', 'Cash Out Tier I', '', 'Cash Out Tier II', '']
          rlt.push(row)
          row = [
            'Loan Amount',
            '',
            'Reserves',
            'Min DSCR',
            'FICO',
            'LTV',
            'LTC',
            'LTV',
            'LTC',
            'LTV',
            'LTC',
            'Rates Tier I',
            'Rates Tier II',
            'Rates Tier III',
          ]
          rlt.push(row)
          ficos.map((fico: number, index: number) => {
            row = [
              '',
              '',
              '',
              '',
              fico,
              purchaseLTVs[index],
              purchaseLTCs[index],
              cashOutLTVs.Tier1[index],
              cashOutLTCs.Tier1[index],
              cashOutLTVs.Tier2[index],
              cashOutLTCs.Tier2[index],
              TierRates.Tier1[index],
              TierRates.Tier2[index],
              TierRates.Tier3[index],
            ]
            if (index === 0) {
              row[0] = loanAmount.from
              row[1] = loanAmount.to
              row[2] = monthsReserve
              row[3] = minDscr
            }
            rlt.push(row)
          })
          rlt.push([])
        })
        resolve({
          sheet: rlt,
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  // Function Excel Parse FastMoney Program
  parseFastMoneyProgram = (data: Record<string, any>) => {
    return new Promise((resolve, reject) => {
      try {
        const { Investor, PrimarySecond } = data
        let rlt = []
        let row = ['Investor Only', '', '', '', '', '', 'No Income', '', '', '', '', Investor.pricing]
        rlt.push(row)
        row = [
          'Loan Amount',
          '',
          'Reserves',
          'Min Fico',
          'FICO',
          '',
          'Transaction',
          'AIV-LTV',
          'ARV-LTV',
          'LTC',
          'LTP',
          'Rates',
        ]
        rlt.push(row)
        row = [
          Investor.loanAmount.from,
          Investor.loanAmount.to,
          Investor.monthsReserve,
          Investor.minFico,
          Investor.rates.purchaseFixFlip.fico.from,
          Investor.rates.purchaseFixFlip.fico.to,
          'Purchase - Fix and Flip',
          Investor.rates.purchaseFixFlip.aiv_ltv,
          Investor.rates.purchaseFixFlip.arv_ltv,
          Investor.rates.purchaseFixFlip.ltc,
          Investor.rates.purchaseFixFlip.ltp,
          Investor.rates.purchaseFixFlip.rate,
        ]
        rlt.push(row)
        row = [
          '',
          '',
          '',
          '',
          Investor.rates.purchaseAcquisition.fico.from,
          Investor.rates.purchaseAcquisition.fico.to,
          'Purchase Acquisiton Only',
          Investor.rates.purchaseAcquisition.aiv_ltv,
          Investor.rates.purchaseAcquisition.arv_ltv,
          Investor.rates.purchaseAcquisition.ltc,
          Investor.rates.purchaseAcquisition.ltp,
          Investor.rates.purchaseAcquisition.rate,
        ]
        rlt.push(row)
        row = [
          '',
          '',
          '',
          '',
          Investor.rates.refinanceFixFlip.fico.from,
          Investor.rates.refinanceFixFlip.fico.to,
          'Refinance - Fix and Flip',
          Investor.rates.refinanceFixFlip.aiv_ltv,
          Investor.rates.refinanceFixFlip.arv_ltv,
          Investor.rates.refinanceFixFlip.ltc,
          Investor.rates.refinanceFixFlip.ltp,
          Investor.rates.refinanceFixFlip.rate,
        ]
        rlt.push(row)
        row = [
          '',
          '',
          '',
          '',
          Investor.rates.refinance.fico.from,
          Investor.rates.refinance.fico.to,
          'Refinance',
          Investor.rates.refinance.aiv_ltv,
          Investor.rates.refinance.arv_ltv,
          Investor.rates.refinance.ltc,
          Investor.rates.refinance.ltp,
          Investor.rates.refinance.rate,
        ]
        rlt.push(row)
        rlt.push([])
        row = ['Primary / Second', '', '', '', '', '', 'No Income', '', PrimarySecond.pricing]
        rlt.push(row)
        row = ['Loan Amount', '', 'Reserves', 'Min FICO', 'FICO', '', 'Transaction', 'AIV-LTV', 'Rates']
        rlt.push(row)
        row = [
          PrimarySecond.loanAmount.from,
          PrimarySecond.loanAmount.to,
          PrimarySecond.monthsReserve,
          PrimarySecond.minFico,
          PrimarySecond.rates.bridge1.fico.from,
          PrimarySecond.rates.bridge1.fico.to,
          'Bridge Loan',
          PrimarySecond.rates.bridge1.aiv_ltv,
          PrimarySecond.rates.bridge1.rate,
        ]
        rlt.push(row)
        row = [
          '',
          '',
          '',
          '',
          PrimarySecond.rates.bridge2.fico.from,
          PrimarySecond.rates.bridge2.fico.to,
          'Bridge Loan',
          PrimarySecond.rates.bridge2.aiv_ltv,
          PrimarySecond.rates.bridge2.rate,
        ]
        rlt.push(row)
        resolve({
          sheet: rlt,
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  // Function Excel Parse SoftMoney Program
  parseSoftMoneyProgram = (data: Record<string, any>) => {
    return new Promise((resolve, reject) => {
      try {
        const { SecondInvestor } = data
        let rlt = []
        let row = ['Second & Investor', '', '', '', '', 'No Income', '', SecondInvestor.pricing]
        rlt.push(row)
        row = ['Loan Amount', '', 'Reserves', 'Min FICO', 'FICO', 'Purch-R/T', 'Cash Out', 'Rates']
        rlt.push(row)
        const { loanAmount, minDscr, monthsReserve, ficos, purchaseLTVs, cashOutLTVs, rates } = SecondInvestor
        ficos.map((item: number, index: number) => {
          row = ['', '', '', '', item, purchaseLTVs[index], cashOutLTVs[index], rates[index]]
          if (index === 0) {
            row[0] = loanAmount.from
            row[1] = loanAmount.to
            row[2] = monthsReserve
            row[3] = minDscr
          }
          rlt.push(row)
        })
        resolve({
          sheet: rlt,
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  // Function Excel Parse Commercial DSCR Program
  parseCommercialDSCRProgram = (data: Record<string, any>) => {
    return new Promise((resolve, reject) => {
      try {
        let rlt: any = []
        Object.keys(data)
          .sort()
          .map((key) => {
            const {
              loanAmount,
              minDscr,
              monthsReserve,
              ficos,
              purchaseLTVs,
              refinanceLTVs,
              purchaseRates,
              refinanceRates,
              purchasePrice,
              refinancePrice,
            } = data[key]

            let row = [key, '', '', '', '', 'No Income', `${purchasePrice}`, '', `${refinancePrice}`]
            rlt.push(row)
            row = [
              'Loan Amount',
              '',
              'Reserves',
              'Min DSCR',
              'FICO',
              'Purchase LTV',
              'Purchase Rates',
              'Refinance LTV',
              'Refinance Rates',
            ]
            rlt.push(row)
            ficos.map((fico: number, index: number) => {
              row = [
                '',
                '',
                '',
                '',
                fico,
                purchaseLTVs[index],
                purchaseRates[index],
                refinanceLTVs[index],
                refinanceRates[index],
              ]
              if (index === 0) {
                row[0] = loanAmount.from
                row[1] = loanAmount.to
                row[2] = monthsReserve
                row[3] = minDscr
              }
              rlt.push(row)
            })
            rlt.push([])
          })
        resolve({
          sheet: rlt,
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  // Function Excel Parse Commercial Only Programmed
  parseNonDscrProgram = (data: ICommercialOnlyProgrammed, isPoints: boolean) => {
    return new Promise((resolve, reject) => {
      try {
        let rlt: any[] = []

        const {
          baseRates,
          basePrices,
          rateSpreads,
          exitFees,
          ltvLimits,
          ficos,
          monthsReserve,
          monthsReserveAdjustment,
          experience,
          propertyTypes,
          citizenships,
          bankruptcy,
          foreclosure,
          deedInLieu,
          shortsale,
          mortgageLates,
          forbearance,
          rehabBudgetTypes,
          rehabBudgetTypesAdjustment,
          maxRatePriceAdjustment,
          experienceAdjustment,
          loanTerm,
          loanTermAdjustment,
          aivLtvs,
          aivLtvAdjustment,
          ltcs,
          ltcAdjustment,
          ficoAdjustment,
          propertyTypeAdjustment,
          citizenshipAdjustment,
          bankruptcyAdjustment,
          foreclosureAdjustment,
          deedInLieuAdjustment,
          shortsaleAdjustment,
          mortgageLatesAdjustment,
          forbearanceAdjustment,
          loanAmount,
        } = data

        let totalRows =
          ficos.length +
          monthsReserve.length +
          experience.length +
          loanTerm.length +
          aivLtvs.length +
          ltcs.length +
          propertyTypes.length +
          citizenships.length +
          bankruptcy.length +
          foreclosure.length +
          deedInLieu.length +
          shortsale.length +
          mortgageLates.length +
          forbearance.length +
          37

        totalRows += rehabBudgetTypes ? rehabBudgetTypes.length + 2 : 0

        const stackCols = [8, 9, 10, 11]
        const titleRows = [1, 6]
        const rateTitleCols = [0, 1, 2, 3, 4, 5, 6]

        const ltvTitleCols = [0, 1, 2, 3, 4]

        const editRows = [2, 3, 4]
        const ltvEditCols = [1, 2, 3, 4]
        const rateEditCols = [1, 2, 3, 4, 5, 6]

        const rightAlignRows = [2, 3, 4]

        const titles: string[] = ['0-0', '7-0']
        const editables: string[] = ['0-1', '0-2']
        const rightAlignCells: string[] = []

        const rows: Record<string, number[] | number> = {
          centerTitleRows: [],
          maxRatePriceAdjRow: [],
          experienceRows: [],
          loanTermAdjRows: [],
          aivLtvAdjRows: [],
          ltcAdjRows: [],
          ficoAdjRows: [],
          monthsReserveAdjRows: [],
          propertyTypeRateAdjRows: [],
          citizenshipRateAdjRows: [],
          bankruptcyRateAdjRows: [],
          foreclosureRateAdjRows: [],
          deedInLieuRateAdjRows: [],
          shortsaleRateAdjRows: [],
          mortgageLatesRateAdjRows: [],
          forbearanceRateAdjRows: [],
          rehabBudgetTypesRateAdjRows: [],
          rateRowStart: NaN,
          rateRowEnd: NaN,
        }

        rlt.push(['Min/Max Loan Amount', loanAmount.from, loanAmount.to, '', '', '', ''])

        rlt.push(['Max LTV Limits', 'AIV-LTV', 'ARV-LTV', 'LTC', 'LTP', '', ''])

        rlt.push([
          'Purchase',
          ltvLimits.purchase.aivLtv,
          ltvLimits.purchase.arvLtv,
          ltvLimits.purchase.ltc,
          ltvLimits.purchase.ltp,
          '',
          '',
        ])

        rlt.push([
          'No-Cashout',
          ltvLimits.nocashout.aivLtv,
          ltvLimits.nocashout.arvLtv,
          ltvLimits.nocashout.ltc,
          ltvLimits.nocashout.ltp,
          '',
          '',
        ])

        rlt.push([
          'Cashout',
          ltvLimits.cashout.aivLtv,
          ltvLimits.cashout.arvLtv,
          ltvLimits.cashout.ltc,
          ltvLimits.cashout.ltp,
          '',
          '',
        ])

        rlt.push(['', '', '', '', '', '', ''])

        let posY = 6
        rows.rateRowStart = posY
        // Rate & Price Adjustment Label Rows
        titleRows.push(posY)
        titleRows.push(posY + 1)

        rlt.push(['Rate & Price Adjustments', 'Purchase', '', 'No-Cashout', '', 'Cashout', ''])

        rlt.push(['', 'Rate', 'Price', 'Rate', 'Price', 'Rate', 'Price'])
        posY += 2

        // Max Rate & Price Adjustment Row
        titles.push(`${posY}-0`)
        editRows.push(posY)
        ;(rows.maxRatePriceAdjRow as number[]).push(posY)
        rlt.push([
          'Max Rate & Price Adjustment',
          maxRatePriceAdjustment.purchase.rate,
          maxRatePriceAdjustment.purchase.price,
          maxRatePriceAdjustment.nocashout.rate,
          maxRatePriceAdjustment.nocashout.price,
          maxRatePriceAdjustment.cashout.rate,
          maxRatePriceAdjustment.cashout.price,
        ])
        posY += 2
        rlt.push(['', '', '', '', '', '', ''])

        const ratePriceAdjArr = [
          {
            title: 'Experience',
            data: experience,
            adjustment: experienceAdjustment,
            key: 'experienceRows',
            isRange: false,
          },
          {
            title: 'Loan Term',
            data: loanTerm,
            adjustment: loanTermAdjustment,
            key: 'loanTermAdjRows',
            isRange: false,
          },
          {
            title: 'AIV-LTV',
            data: aivLtvs,
            adjustment: aivLtvAdjustment,
            key: 'aivLtvAdjRows',
            isRange: true,
          },
          {
            title: 'LTC',
            data: ltcs,
            adjustment: ltcAdjustment,
            key: 'ltcAdjRows',
            isRange: true,
          },
          {
            title: 'FICO',
            data: ficos,
            adjustment: ficoAdjustment,
            key: 'ficoAdjRows',
            isRange: true,
          },
          {
            title: 'Months Reserve',
            data: monthsReserve,
            adjustment: monthsReserveAdjustment,
            key: 'monthsReserveAdjRows',
            isRange: true,
          },
          {
            title: 'Property Type',
            data: propertyTypes,
            adjustment: propertyTypeAdjustment,
            key: 'propertyTypeRateAdjRows',
            isRange: false,
          },
          {
            title: 'Citizenship',
            data: citizenships,
            adjustment: citizenshipAdjustment,
            key: 'citizenshipRateAdjRows',
            isRange: false,
          },
          {
            title: 'Bankruptcy',
            data: bankruptcy,
            adjustment: bankruptcyAdjustment,
            key: 'bankruptcyRateAdjRows',
            isRange: false,
          },
          {
            title: 'Foreclosure',
            data: foreclosure,
            adjustment: foreclosureAdjustment,
            key: 'foreclosureRateAdjRows',
            isRange: false,
          },
          {
            title: 'Short Sale',
            data: shortsale,
            adjustment: shortsaleAdjustment,
            key: 'shortsaleRateAdjRows',
            isRange: false,
          },
          {
            title: 'Deed In Lieu',
            data: deedInLieu,
            adjustment: deedInLieuAdjustment,
            key: 'deedInLieuRateAdjRows',
            isRange: false,
          },
          {
            title: 'Mortgage Lates',
            data: mortgageLates,
            adjustment: mortgageLatesAdjustment,
            key: 'mortgageLatesRateAdjRows',
            isRange: false,
          },
          {
            title: 'Forbearance / Loan Modification',
            data: forbearance,
            adjustment: forbearanceAdjustment,
            key: 'forbearanceRateAdjRows',
            isRange: false,
          },
          {
            title: 'Rehab Budget Type',
            data: rehabBudgetTypes,
            adjustment: rehabBudgetTypesAdjustment,
            key: 'rehabBudgetTypesRateAdjRows',
            isRange: false,
          },
        ]

        ratePriceAdjArr.forEach((v) => {
          if (v.data) {
            // Title Row
            titleRows.push(posY)
            rlt.push([v.title, '', '', '', '', '', ''])
            posY++

            // Adjustment Rows
            v.data.forEach((item: any, index) => {
              rlt.push([
                v.title == 'FICO' && (item as any) === CreditNoScore
                  ? CreditNoScore
                  : v.isRange
                  ? `${item.from} ${isEmpty(item.to) ? '+' : '― ' + item.to}`
                  : item?.label
                  ? item.label
                  : item,
                v.adjustment.purchase[index].rate,
                v.adjustment.purchase[index].price,
                v.adjustment.nocashout[index].rate,
                v.adjustment.nocashout[index].price,
                v.adjustment.cashout[index].rate,
                v.adjustment.cashout[index].price,
              ])
              rightAlignRows.push(posY)
              editRows.push(posY)
              ;(rows[v.key] as number[]).push(posY)
              posY++
            })
            posY++
            rlt.push(['', '', '', '', '', '', ''])
          }
        })

        rows.rateRowEnd = posY

        // Draw Rate & Price
        rlt[0] = [...rlt[0], '', 'Base Rate', `Base ${isPoints ? 'Points' : 'Price'}`, 'Rate Spread', 'Exit Fee']

        baseRates.forEach((_, index: number) => {
          rlt[index + 1] = [
            ...rlt[index + 1],
            '',
            baseRates[index],
            basePrices[index],
            rateSpreads[index],
            exitFees[index],
          ]
        })

        titleRows.forEach((y) => {
          if (y < (rows.rateRowStart as number))
            ltvTitleCols.forEach((x) => {
              titles.push(`${y}-${x}`)
            })
          else if (y < (rows.rateRowEnd as number))
            rateTitleCols.forEach((x) => {
              titles.push(`${y}-${x}`)
            })
        })

        stackCols.forEach((x) => {
          titles.push(`0-${x}`)
        })

        for (let y = 1; y < totalRows; y++) {
          stackCols.forEach((x) => {
            editables.push(`${y}-${x}`)
          })
        }

        editRows.forEach((y) => {
          if (y < (rows.rateRowStart as number))
            ltvEditCols.forEach((x) => {
              editables.push(`${y}-${x}`)
            })
          else
            rateEditCols.forEach((x) => {
              editables.push(`${y}-${x}`)
            })
        })

        rightAlignRows.forEach((y) => {
          rightAlignCells.push(`${y}-0`)
        })

        resolve({
          sheet: rlt,
          titles: titles,
          editables: editables,
          rightAlignCells: rightAlignCells,
          rows: rows,
        })
      } catch (error) {
        reject(error)
      }
    })
  }
}

export default new ExcelService()
